<template>
  <div v-if="notaryAppointment" class="my-10">
    <DatePicker
      :label="$t('settingsPopup.admin.objects.edit.marketing.date')"
      :date="notaryAppointment.termin"
      @update="updateDate"
    />

    <v-checkbox
      v-model="notaryAppointment.success"
      :label="$t('settingsPopup.admin.objects.edit.marketing.successCheckbox')"
    />
    <v-btn @click="saveNotartermin" color="secondary">{{
      $t("settingsPopup.admin.objects.edit.marketing.saveBtn")
    }}</v-btn>
  </div>
  <div v-else class="my-10">
    <v-btn :loading="loading" @click="createNotartermin" color="accent">{{
      $t("settingsPopup.admin.objects.edit.marketing.loadingBtn")
    }}</v-btn>
  </div>
</template>

<script>
import {
  createNotaryAppointment,
  editNotaryAppointment,
  getNotaryAppointment,
} from "@/services/admin.service";
import DatePicker from "@/components/admin/object/DatePicker";

export default {
  name: "EditNotarTermin",
  components: { DatePicker },
  props: {
    objectId: Number,
  },
  data() {
    return {
      notaryAppointment: null,
      loading: false,
    };
  },
  computed: {},
  created() {
    this.getNotarTermin();
  },
  methods: {
    updateDate(value) {
      this.notaryAppointment.termin = value;
    },
    async saveNotartermin() {
      this.loading = true;
      this.notaryAppointment = await editNotaryAppointment(
        this.notaryAppointment
      );
      this.loading = false;

      location.href = "/admin/objekt/" + this.objectId;
    },
    async createNotartermin() {
      this.notaryAppointment = await createNotaryAppointment(this.objectId);
    },
    async getNotarTermin() {
      this.notaryAppointment = await getNotaryAppointment(this.objectId);
    },
  },
};
</script>

<style scoped></style>
