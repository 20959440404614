<script>
export default {
  name: "CurrencyDisplay",

  props:{
    currency:{
      type:String,
      default:"€"
    },
    number: Number,
  }
}
</script>

<template>
  <span>
    <span v-text="number"></span>
    <span v-text="currency"></span>
  </span>
</template>

<style scoped>

</style>