<template>
  <span>{{ getEnumTitle }}</span>
</template>

<script>
export default {
  name: "EnumDisplayLanguage",
  props: {
    enumName: {
      type: String,
      required: true,
    },
    enumItem:{
      type: String,
      required:true
    }
  },
  computed: {
    getEnumTitle() {
      const enumValue = this.$t('enum.'+this.enumName+"."+this.enumItem);
      if (!enumValue) return this.enumItem;
      return enumValue;
    },
  },
};
</script>
