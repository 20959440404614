
export function betterReadableNumber(number) {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2); // Limit to 2 decimal places
  }
  return parts.join(",");
}

export function shortenNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + ' B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + ' M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + ' K';
  } else {
    return num.toString();
  }
}

export function calculateDistance(point1, point2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(point2[0] - point1[0]); // deg2rad below
  const dLng = deg2rad(point2[1] - point1[1]);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(point1[0])) *
      Math.cos(deg2rad(point2[0])) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
