<template>
  <v-dialog v-model="show">
    <template #activator="{ props }">
      <v-badge v-bind="props" floating>
        <div v-if="isGkObject" class="text-center" style="font-size: 2rem">
          <span class="mr-1"
            >{{
              $t("settingsPopup.admin.objects.edit.brokerCommissionGk.title")
            }} </span
          ><br /><br /><ReadableNumber :number="calculatedRefferalFee" :number-format="ENumberFormat.CURRENCY" />
        </div>
        <div v-else class="text-center" style="font-size: 2rem">
          <span v-html="coins + '€'"></span>
        </div>

        <template #badge>
          <v-icon icon="mdi-pencil"></v-icon>
        </template>
      </v-badge>
    </template>
    <v-card>
      <v-card-text>
        <v-text-field
          ref="value"
          @click:append="updateValue"
          append-icon="mdi-content-save"
          v-model="saleReactive.value"
          :label="
            $t('settingsPopup.admin.objects.edit.brokerCommissionGk.fairValue')
          "
        />
        <v-text-field
          ref="actualValue"
          @click:append="updateActualValue"
          append-icon="mdi-content-save"
          v-model="saleReactive.actualValue"
          :label="
            $t('settingsPopup.admin.objects.edit.brokerCommissionGk.salesValue')
          "
        />
        <v-text-field
          ref="provision"
          @click:append="updateProvision"
          hint="Den Provisionssatz der Innenprovision aus dem Verkäufervertrag"
          append-icon="mdi-content-save"
          v-model="saleReactive.provision"
          :label="
            $t('settingsPopup.admin.objects.edit.brokerCommissionGk.provision')
          "
        />
        <v-text-field
            v-if="!isGkObject"
          ref="tipsterProvision"
          @click:append="updateTipsterProvision"
          append-icon="mdi-content-save"
          v-model="saleReactive.tipsterProvision"
          :label="
            $t(
              'settingsPopup.admin.objects.edit.brokerCommissionGk.tipgiverProvision'
            )
          "
        />
        <v-text-field
          v-if="isGkObject"
          ref="potentialRefferalFee"
          @click:append="updateRefferalFee"
          append-icon="mdi-content-save"
          v-model="saleReactive.expectedReferralFee"
          :label="
            $t(
              'settingsPopup.admin.objects.edit.brokerCommissionGk.brokerCommission'
            )
          "
        />
        <div v-if="isGkObject" v-html="$t('settingsPopup.admin.objects.edit.brokerCommissionGk.brokerCommissionHint', {calculated:this.calculatedRefferalFee})"></div>

      </v-card-text>
      <v-card-actions>
        <v-btn block @click="show = false" type="text">{{
          $t("settingsPopup.admin.objects.edit.brokerCommissionGk.closeBtn")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  updateActualValue,
  updateProvision,
  updateRefferalFee,
  updateTipsterProvision,
  updateValue,
} from "@/services/admin.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { isGkObject } from "@/services/object.service";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import ENumberFormat from "@/enums/ENumberFormat";

export default {
  name: "ValueOverviewAdmin",
  components: {ReadableNumber},
  setup() {
    const authStore = useAuthUserStore();
    const isAdmin = authStore.hasRole("ROLE_ADMIN");
    return { isAdmin };
  },
  async created() {
    this.isGkObject = await this.isGk();
  },
  data() {
    return {
      isGkObject: false,
      show: false,
    };
  },
  props: {
    sale: Object,
  },
  methods: {
    async isGk() {
      return await isGkObject(this.sale.objectId);
    },
    async updateValue() {
      let result = await updateValue(
        this.sale.objectId,
        this.saleReactive.value
      );
      this.$refs.value.$el.style.color = "green";
      console.log("Ref", this.$refs.value.$el);
      this.$emit("update", result);
    },
    async updateActualValue() {
      let result = await updateActualValue(
        this.sale.objectId,
        this.saleReactive.actualValue
      );
      this.$refs.actualValue.$el.style.color = "green";
      this.$emit("update", result);
    },
    async updateProvision() {
      let result = await updateProvision(
        this.sale.objectId,
        this.saleReactive.provision
      );
      this.$refs.provision.$el.style.color = "green";
      this.$emit("update", result);
    },
    async updateTipsterProvision() {
      let result = await updateTipsterProvision(
        this.sale.objectId,
        this.saleReactive.tipsterProvision
      );
      this.$refs.tipsterProvision.$el.style.color = "green";
      this.$emit("update", result);
    },
    async updateRefferalFee() {
      let result = await updateRefferalFee(
        this.sale.objectId,
        this.saleReactive.expectedReferralFee
      );
      this.$refs.potentialRefferalFee.$el.style.color = "green";
      this.$emit("update", result);
    },
  },
  computed: {
    ENumberFormat() {
      return ENumberFormat
    },
    saleReactive() {
      return this.sale;
    },
    calculatedRefferalFee() {
      if (!this.sale) return 0;
      let value = this.sale.value;
      if (this.sale.actualValue) value = this.sale.actualValue;
      return (
          (value * (this.sale.provision / 100) * (this.sale.expectedReferralFee / 100)).toFixed(2)
      );
    },
    formula() {
      if (!this.sale) return 0;
      let value = this.sale.value;
      if (this.sale.actualValue) value = this.sale.actualValue;
      return (
        value * (this.sale.provision / 100) * (this.sale.tipsterProvision / 100)
      );
    },
    coins() {
      return new Intl.NumberFormat("en-DE").format(Math.round(this.formula));
    },
  },
};
</script>

<style scoped></style>
