<template>
  <v-menu v-model="show" :close-on-content-click="false">
    <template #activator="{ props }">
      <v-chip
        rounded="lg"
        class="py-6"
        density="compact"
        tile
        variant="tonal"
        v-bind="props"
      >
        <template #append>
          <v-icon v-if="show">mdi-chevron-up</v-icon>
          <span style="z-index: 10" v-else>
            <v-icon v-if="!modelValueC[0] && !modelValueC[1]"
              >mdi-chevron-down</v-icon
            >
            <v-icon style="z-index: 10" @click.prevent="removeFilter" v-else
              >mdi-close-circle</v-icon
            >
          </span>
        </template>
        <span v-if="modelValueC[0] && modelValueC[1]"
          ><ReadableNumber
            :prefix="prefix"
            :number-format="numberFormat"
            :number="modelValueC[0]" />
          -
          <ReadableNumber
            :prefix="prefix"
            :number-format="numberFormat"
            :number="modelValueC[1]"
        /></span>
        <span v-else-if="modelValueC[0]"
          >Min
          <ReadableNumber
            :prefix="prefix"
            :number-format="numberFormat"
            :number="modelValueC[0]"
        /></span>
        <span v-else-if="modelValueC[1]"
          >Max
          <ReadableNumber
            :prefix="prefix"
            :number-format="numberFormat"
            :number="modelValueC[1]"
        /></span>
        <span v-else>{{ label }}</span>
      </v-chip>
    </template>
    <v-sheet class="px-10 py-5">
      <FilterItem
        :label="label"
        :min="fixedMinMax[0]"
        :max="fixedMinMax[1]"
        :prefix="prefix"
        v-model="modelValueC"
      />
    </v-sheet>
  </v-menu>
</template>
<script>
import FilterItem from "@/views/geschaeftskunde/FilterItem.vue";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import ENumberFormat from "@/enums/ENumberFormat";
export default {
  name: "FilterMenu",
  components: { ReadableNumber, FilterItem },
  data() {
    return {
      show: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    numberFormat: {
      type: ENumberFormat,
      default: ENumberFormat.NONE,
    },
    prefix: {
      type: String,
      default: null,
    },
    fixedMinMax: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removeFilter() {
      this.$emit("update:modelValue", [0, null]);
    },
  },
  computed: {
    modelValueC: {
      get() {
        return this.modelValue.map((value) => (isNaN(value) ? 0 : value));
      },
      set(value) {
        this.$emit(
          "update:modelValue",
          value.map((val) => (isNaN(val) ? 0 : val))
        );
      },
    },
  },
};
</script>
