<template>
  <v-dialog v-model="showReactive">
    <template #activator="{props}">
  <StepListItem v-bind="props" :disabled="disabled" :title="$t('process.verification.title')" :status="state.state" :message="state.message" :message-color="state.color" />
    </template>
    <v-card v-if="verification==null || verification === ''">
      <v-card-text>
        <div v-html="$t('process.verification.text')"></div>
        <v-expansion-panels class="my-2">
          <v-expansion-panel color="secondary" :title="objectName">
            <template #text>
              <ObjectData :object="object" readonly/>
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert  density="compact" border="start" border-color="warning">
          <template #title>
          <span style="font-size: 0.9rem">
           {{$t('process.verification.warning')}}
          </span>
          </template>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="showReactive=false">{{$t('buttons.cancel')}}</v-btn><v-spacer/><v-btn color="success" @click="requestVerification()">{{$t('process.verification.button')}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <v-progress-circular indeterminate size="25"></v-progress-circular>
        <div v-html="$t('process.verification.running')"></div>

      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
import ObjectData from "@/components/objects/ObjectData";
import {getObjectName} from "@/services/object.service";
import {getVerification, requestVerification} from "@/services/verification.service";
import {useObjectStore} from "@/stores/objects.module";
export default {
  name: "VerificationStep",
  components:{
    ObjectData,
    StepListItem
  },
  setup(){
    const objectStore = useObjectStore();
    return{
      objectStore
    }
  },
  data(){
    return {
      verification: null
    }
  },
  created() {
    this.getVerification();
  },

  methods:{
    async requestVerification() {
      this.verification = await requestVerification(this.object.id);
      this.objectStore.updateRequirements(this.verification.objectId);
      this.objectStore.updateStates(this.verification.objectId);

    },
    async getVerification() {
      console.log(this.object);
      this.verification = await getVerification(this.object.id);
    }
  },

  props:{
    object:Object,
    show:Boolean,
    requirements:Array,
    statusRequirements:Object,
    state:Object
  },
  computed:{
    objectName(){
      return getObjectName(this.object);
    },
    showReactive:{
      get(){
        return this.show;
      },set(value){
        this.$emit('toggleDialog',value)
      }
    },
    disabled(){
      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>