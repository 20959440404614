const EGkProgressStep = {
  SEND_TEASER_EXPOSE: {
    german: "Teaser/Expose senden",
    english: "Send Teaser/Expose",
  },
  AWAIT_APPROVAL: { german: "Auf Freigabe warten", english: "Await Approval" },
  ADD_PROPERTY_IN_ESTATEBOOST: {
    german: "Immobilie in EstateBoost anlegen",
    english: "Add Property in EstateBoost",
  },
  VERIFY_INPUT: { german: "Eingabe verifizieren", english: "Verify Input" },
  CREATE_EXPOSE_DATA_ROOM: {
    german: "Expose/Datenraum erstellen",
    english: "Create Expose/Data Room",
  },
  EVALUATE_PROPERTY: {
    german: "Einwertung der Immobilie",
    english: "Evaluate Property",
  },
  APPROVE_EVALUATION: {
    german: "Einwertung freigeben",
    english: "Approve Evaluation",
  },
  OPEN_DUE_DILIGENCE_PROCESS: {
    german: "Due Diligence Prozess eröffnen",
    english: "Open Due Diligence Process",
  },
  INITIATE_MARKET_CONTACT: {
    german: "Marktansprache durch Matching",
    english: "Initiate Market Contact",
  },
  MAKE_BINDING_OFFER: {
    german: "Verbindliche Angebotsabgabe",
    english: "Make Binding Offer",
  },
  COMPLETE_NOTARIZATION: {
    german: "Notarielle Beurkundung abschließen",
    english: "Complete Notarization",
  },
  RECEIVE_EXTERNAL_COMMISSION: {
    german: "Eingang der Außenprovision",
    english: "Receive External Commission",
  },
  PAY_TIP_PROVIDER_COMMISSION: {
    german: "Tippgeberprovision auszahlen",
    english: "Pay Tip Provider Commission",
  },
  ANALYZE_PROPERTY_DATA: {
    german: "Objektdaten erheben",
    english: "Analyze Property Data",
  },
  CONDUCT_MARKET_ANALYSIS: {
    german: "Marktanalyse durchführen",
    english: "Conduct Market Analysis",
  },
  SELECT_EVALUATION_METHOD: {
    german: "Bewertungsansatz auswählen",
    english: "Select Evaluation Method",
  },
  INTERNAL_VALIDATION: {
    german: "Interne Validierung",
    english: "Internal Validation",
  },
  APPROVE_COMMUNICATION: {
    german: "Freigabe und Kommunikation",
    english: "Approve Communication",
  },
  PREPARE_NEGOTIATION_STRATEGY: {
    german: "Verhandlungsstrategie vorbereiten",
    english: "Prepare Negotiation Strategy",
  },
  START_NEGOTIATIONS: {
    german: "Verhandlungen einleiten",
    english: "Start Negotiations",
  },
  CONDUCT_NEGOTIATIONS: {
    german: "Verhandlungen durchführen",
    english: "Conduct Negotiations",
  },
  ADJUST_CONTRACT_TERMS: {
    german: "Vertragsbedingungen anpassen",
    english: "Adjust Contract Terms",
  },
  FINALIZE_AND_SIGN_CONTRACT: {
    german: "Vertragsabschluss finalisieren",
    english: "Finalize and Sign Contract",
  },
};

export default EGkProgressStep;
