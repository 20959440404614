<template>
  <v-container>
    <v-form @submit.prevent="createCourseMethod">
      <v-text-field
        @change="suggestId"
        :label="$t('settingsPopup.admin.trainingCenter.courseSection.addTitle')"
        v-model="createCourse.title"
      />
      <v-text-field label="Id" v-model="createCourse.id" />
      <v-textarea
        :label="
          $t('settingsPopup.admin.trainingCenter.courseSection.addDescription')
        "
        v-model="createCourse.description"
      />
      <v-text-field
        type="number"
        :label="
          $t('settingsPopup.admin.trainingCenter.courseSection.addChapter')
        "
        v-model="createCourse.chapter"
      />
      <v-btn type="submit" block>{{
        $t("settingsPopup.admin.trainingCenter.courseSection.addSaveBtn")
      }}</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import CreateCourse from "@/models/course";
import { replaceUmlaute } from "@/services/utils.service";
import { createCourse } from "@/services/courses.service";

export default {
  name: "CreateCourse",
  data() {
    return {
      createCourse: new CreateCourse({ sectionId: this.sectionId }),
    };
  },
  props: {
    sectionId: Number,
  },
  methods: {
    suggestId(e) {
      if (this.createCourse.id) return null;
      let value = e.target.value;
      value = value.toLowerCase();
      value = value.replaceAll(" ", "_");
      value = replaceUmlaute(value);
      this.createCourse.id = value;
    },
    async createCourseMethod() {
      const response = await createCourse(this.createCourse);

      console.log("CreateCourse", response);
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
