<template>
  <v-dialog v-model="showReactive">
    <template #activator="{props}">
      <StepListItem v-bind="props" :disabled="disabled" :title="$t('process.marketing.title')" :status="state.state" :message="state.message" :message-color="state.color" />

    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>{{$t('object.fairValue')}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
      <div>
        <strong>{{$t('object.expected')}} </strong>
        <span v-html="value+'€'"></span>
      </div> <div>
      <strong>{{$t('object.actual')}}</strong>
      <span v-html="actualValue"></span>
    </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
export default {
  name: "MarketingStep",
  components: {StepListItem},
  props:{
    show:Boolean,
    requirements:Array,
    state:Object,
    sale:Object
  },
  computed:{
    value(){
      return new Intl.NumberFormat('en-DE').format(Math.ceil(this.sale.value));
    },
    actualValue(){
      if(this.sale.actualValue<=0) return "-";
      else return new Intl.NumberFormat('en-DE').format(Math.ceil(this.sale.actualValue))+"€";
    },
    showReactive:{
      get(){
        return this.show;
      },set(value){
        this.$emit('toggleDialog',value)
      }
    },
    disabled(){
      for(let requirement of this.requirements){
        if(!requirement.value) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>