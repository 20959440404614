<template>
<v-dialog max-width="500px">
  <template #activator="{props}">
    <v-icon v-bind="props" icon="mdi-help"></v-icon>
  </template>
  <v-card>
    <v-card-text>
      <span v-text="$t('process.coinFormula')"></span><v-chip>{{tippgeberProvision}}%</v-chip>
      </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "CoinFormulaDisplay",
  props:{
    marktwert:Number,
    provision:Number,
    tippgeberProvision:Number
  }
}
</script>

<style scoped>

</style>