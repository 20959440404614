<template>
  <v-badge :model-value="unreadNotifications.length>0" color="green" dot :content="unreadNotifications.length" offset-y="5" offset-x="10">
    <v-btn :class="{'animate__animated animate__swing animate__delay-1s animate__repeat-3 animate__fast':unreadNotifications.length>0}" class="" @click="show=!show" variant="plain" icon="mdi-bell">

    </v-btn>

  </v-badge>
  <v-navigation-drawer touchless temporary location="bottom" v-model="show" class="elevation-0 bg-secondary pa-0 py-2 px-2 rounded-t-xl" color="transparent" app order="2">
  <div style="position: absolute;top:0; height:40px;width:95%;left:50%;transform:translateX(-50%);background-color: #3D3E40;z-index:3" class="rounded-xl px-2 mb-5 d-flex justify-space-between align-center">
    <span>{{$t('notification.title')}}</span>
    <v-btn @click="deleteAll" size="small">{{$t('notification.deleteAll')}}</v-btn>
  </div>
    <div v-if="allNotifications.length>0" style="margin-top:40px">
    <v-list-item :prepend-avatar="notification.icon" v-for="notification of allNotifications" :key="notification.id" :subtitle="notification.message" @click="read(notification)" align="left">
     <template #title>
       <span v-html="notification.title"></span><v-badge color="success" inline v-if="notification.state!='READ'" dot></v-badge>
     </template>
      <template #append>
        <v-btn variant="plain" v-if="notification.link" icon="mdi-open-in-app">

        </v-btn>
        <v-btn :loading="deleting" variant="plain"  @click="deleteNotification(notification)" color="red" icon="mdi-close">

        </v-btn>
      </template>

    </v-list-item>
    </div>
    <div v-else style="margin-top:40px">
      <v-list-item :title="$t('notification.noData')"></v-list-item>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {useNotificationStore} from "@/stores/notification.module";

export default {
  name: 'NotificationBell',
  setup(){
    const notificationsStore = useNotificationStore();
    notificationsStore.getAllNotifications();
    return{
      notificationsStore
    }
  },
  data(){
    return{
    show:false,
      deleting:false
    }
  },
  methods:{
    read(notification){
      if(notification.state==='READ') return null;
      else this.notificationsStore.read(notification);
    },
    deleteAll(){
      this.notificationsStore.deleteAll();
    },
    async deleteNotification(notification) {
      this.deleting = true;
      await this.notificationsStore.delete(notification);
      this.deleting=false;
    }
  },
  computed:{
    unreadNotifications(){
      return this.notificationsStore.getUnreadNotifications;
    },
    allNotifications(){
      return this.notificationsStore.getAllRealNotifications;
    }
  },
}
</script>
