import { en } from "vuetify/locale";

export const gb = {
  $vuetify: {
    ...en,
  },
  enum: {
    pending: "Pending",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Send Teaser/Expose",
      AWAIT_APPROVAL: "Await Approval",
      ADD_PROPERTY_IN_ESTATEBOOST: "Add Property in EstateBoost",
      VERIFY_INPUT: "Verify Input",
      CREATE_EXPOSE_DATA_ROOM: "Create Expose/Data Room",
      EVALUATE_PROPERTY: "Property Evaluation",
      APPROVE_EVALUATION: "Approve Evaluation",
      OPEN_DUE_DILIGENCE_PROCESS: "Open Due Diligence Process",
      INITIATE_MARKET_CONTACT: "Initiate Market Contact",
      MAKE_BINDING_OFFER: "Make Binding Offer",
      COMPLETE_NOTARIZATION: "Complete Notarization",
      RECEIVE_EXTERNAL_COMMISSION: "Receive External Commission",
      PAY_TIP_PROVIDER_COMMISSION: "Pay Tip Provider Commission",
      ANALYZE_PROPERTY_DATA: "Analyze Property Data",
      CONDUCT_MARKET_ANALYSIS: "Conduct Market Analysis",
      SELECT_EVALUATION_METHOD: "Select Evaluation Method",
      INTERNAL_VALIDATION: "Internal Validation",
      APPROVE_COMMUNICATION: "Approve Communication",
      PREPARE_NEGOTIATION_STRATEGY: "Prepare Negotiation Strategy",
      START_NEGOTIATIONS: "Start Negotiations",
      CONDUCT_NEGOTIATIONS: "Conduct Negotiations",
      ADJUST_CONTRACT_TERMS: "Adjust Contract Terms",
      FINALIZE_AND_SIGN_CONTRACT: "Finalize and Sign Contract",
    },
    gkProcessState: {
      OPEN: "Open",
      IN_PROGRESS: "In Progress",
      DONE: "Done",
    },
  },
  tasks: "Task | Tasks",
  notification: {
    title: "Notifications",
    deleteAll: "Delete all",
    noData: "No notifications",
  },
  wallet: {
    token: "Token | Tokens",
    coin: "Coin | Coins",
  },
  education: {
    to: "To Education",
  },
  files: {
    download: "Download file",
    upload: "Upload new file",
  },
  process: {
    coinFormula:
      "This value represents your compensation for establishing a brokerage agreement with the property owner through your tip to us. It is calculated based on the resulting brokerage commission, which depends on the sales price. Your share of the seller's commission (internal commission) is",
    ownerConfirmation: {
      title: "Owner's Consent Declaration",
      text: "The owner's consent is the most important part of the process.<br/>EstateBoost stands for <b>transparency</b> and <b>honesty</b>, and explicitly only collects data that is <b>voluntarily</b> and <b>intentionally provided by the owner</b>.",
      alert:
        "We explicitly emphasize that we, as a company, have no interest in data that cannot legally be shared with us.*",
      footNote: "*Any misuse will result in account suspension.",
      confirm:
        "I hereby confirm that I have the consent of the property owner to provide their personal data on our platform.",
      download: "Download PDF template here",
      upload: "Upload new consent declaration",
      noConfirmation: "No consent declaration uploaded",
      noConfirmation2: "No consent given.",
    },
    objectData: {
      title: "Enter Object Data",
    },
    verification: {
      title: "Verification",
      text: "Your object is now ready for verification.<br/>Please review your entries once more.",
      warning:
        "Once you submit the verification, you will no longer be able to edit the object data.",
      button: "Verify now",
      running:
        '<p>Verification is in progress. We will notify you once the owner has been reached and your entries have been verified.</p><p>Current status:<br/> <small class="text-accent" v-html="state.message"></small></p>',
    },
    contract: {
      title: "Brokerage Contract Concluded",
      text: 'The brokerage contract with the owner is <span class="success">active</span>.<br/>We now have until <v-chip >{date}</v-chip> to market "your" property.',
    },
    marketing: {
      title: "Marketing",
    },
    notaryAppointment: {
      title: "Notary Appointment",
    },
    provision: {
      title: "Broker Commission Received",
    },
    collect: {
      title: "Collect Coins",
      text: "You can now collect your coins! Additionally, you can also convert your coins to euros and withdraw them to your registered account.",
      alreadyCollected: "You have already collected your coins.",
    },
    overallProgress: "Overall Progress",
    collectCoins: "Collect Coins Now",
    success: "Success",
    reward: "Coin Reward",
  },
  topBar: {
    saveToHomescreen: "Save the WebApp to your home screen!",
    apple: "iPhone/iPad: Tap the share button and select 'Add to Home Screen'.",
    android: "Tap the three dots and select 'Add to Home Screen'.",
  },
  greeting: {
    morning: "Good Morning",
    lunch: "Lunch Time",
    evening: "Good Evening",
    default: "Good Day",
  },
  buttons: {
    buy: "Buy",
    close: "Close",
    save: "Save",
    send: "Send",
    cancel: "Cancel",
    open: "Open",
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Bank Name",
    accountHolder: "Account Holder",
  },
  user: {
    level: "Level",
    profile: {
      tipster: "Tipster",
      checkEducation:
        "*To add a property, you must complete our privacy training.",
    },
    registeredAt: "Member since",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    mobile: "Mobile Number",
    bio: "Biography",
    birthDate: "Date of Birth",
    messages: {
      emailVerified: "Your email address is verified.",
      emailNotVerified:
        "To fully use the app, you need to verify your email. <br/>Click <b>Verify Email Now</b> and we will send you an email.",
      validateBtn: "Verify Email Now",
      changeBirthDate: "Update Date of Birth",
    },
  },
  address: {
    title: "Addresses",
    showOnMap: "Show on map",
    save: "Add new address",
    street: "Street",
    houseNumber: "House Number",
    zip: "ZIP Code",
    city: "City",
    bundesland: "State",
    country: "Country",
  },
  yourObjects: "Your Properties",
  allObjects: "All Properties",
  form: {
    search: "Search",
    invalidDate: "Invalid Date",
  },
  noData: {
    default: "No data found",
    reactive: "No {obj} found",
    small: "No {obj}",
  },
  object: {
    owner: {
      title: "Property Owner",
      fullName: {
        title: "Owner's Full Name",
        hint: "Please remember that providing this data without the owner's consent is not permitted.",
      },
      email: "Owner's Email",
      telephone: "Owner's Phone Number",
      error: {
        importantData: "Important data is missing!",
        nameMissing: "Owner's name is missing.",
        emailMissing: "Owner's email is missing.",
        phoneMissing: "Owner's phone number is missing.",
      },
    },
    sale: "SALE",
    retirement: "RETIREMENT",
    slots: {
      locked: "Locked Slot",
      unlock: "Unlock Slot",
      buy: {
        confirm:
          "By clicking OK, {token} tokens will be deducted and 1 slot will be added.",
        title: "Buy a new slot to add more properties.",
        insufficientSales:
          "You must sell at least 2 properties to unlock new slots.",
        commercialActivity:
          "From the third property in a year, it is considered a commercial activity. Please note you may need to register a business.",
        priceCalculation:
          "The price is calculated based on your recently completed tips.",
      },
    },
    saled: "sold",
    object: "Object | Objects",
    estate: "Property | Properties",
    price: "Price",
    istFactor: "Yield Factor / Actual Return",
    usableArea: "Usable Area",
    flatArea: "Living Area",
    baseArea: "Plot Area",
    tooltip: "Estimated Broker Commission",
    yearOfConstruction: "Year of Construction",
    onlineSince: "Online Since",
    contacts: "Contact Person",
    addNote: "Add note...",
    noNote: "No note",
    add: "Add Property",
    expected: "estimated",
    actual: "actual",
    provision:"Commission",
    fairValue: "Market Value",
    fairValueExpected: "Estimated Market Value",
    fairValueExpectedExplanation:
      "The estimated market value is calculated based on various factors and may differ from the sales price.",
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Admin Area",
      objects: {
        title: "Properties",
        subtitle: "Manage your properties",
        verification: {
          title: "Verification",
          whyError: "Why did the verification fail?",
          whyErrorPlaceholder: "Reason here...",
          whyErrorLabel: "Reason for Failure",
          send: "Send",
          cancel: "Cancel",
          declarationOfConsent: "Owner's Consent Declaration",
          sellType: "Interest in",
          objectType: "Property Type",
          livingArea: "Living Area",
          baseArea: "Plot Area",
          owner: "Owner",
          createdBy: "created by: ",
        },
        marketing: {
          title: "Marketing",
          edit: "Edit",
          view: "View",
        },
        success: "Success",
        error: "Error",
        edit: {
          brokerCommissionGk: {
            title: "Broker Commission GK",
            fairValue: "Market Value Before Contract",
            salesValue: "Sales Value",
            provision: "Commission in %",
            tipgiverProvision: "Tipgiver Commission in %",
            brokerCommission: "Broker Commission in %",
            closeBtn: "Close",
          },
          userView: "User View",
          overview: {
            title: "Overview",
          },
          agentContract: {
            title: "Broker Contract",
            dateEnd: "Contract End Date",
            signedCheckbox: "Contract Signed",
            loadingBtn: "Create Contract Object",
            saveBtn: "Save",
          },
          marketing: {
            title: "Marketing",
            date: "Notary Appointment on: ",
            successCheckbox: "Property Marketed",
            saveBtn: "Save",
            loadingBtn: "Create Notary Appointment Object",
          },
          payment: {
            title: "Payment",
            saveBtn: "Complete Property for User",
            infoText1: "*User can now collect their coins",
            infoText2: "User can now withdraw their coins",
          },
          fairValue: "Market Value",
          provision: "Commission",
          tipGiver: "Tipgiver",
        },
      },
      trainingCenter: {
        title: "Training Center",
        subtitle: "Edit and Add Trainings",
        courseSection: {
          noData: "No sections found",
          addBtn: "Add",
          addTitle: "Title",
          addDescription: "Description",
          addChapter: "Chapter",
          addSaveBtn: "Upload New Course",
        },
      },
      payout: {
        title: "Payouts",
        subtitle: "All Payouts",
      },
      statistics: {
        title: "Statistics",
        subtitle: "Statistics",
      },
    },
    accountDetails: {
      name: "Account Details",
      details: {},
    },
    address: {
      name: "Addresses",
      details: {},
    },
    bankDetails: {
      name: "Bank Details",
      details: {},
    },
    shareProfile: {
      name: "Share Profile",
      details: {},
    },
    settings: {
      name: "Settings",
      details: {},
    },
    changePassword: {
      name: "Change Password",
      details: {
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        oldPassword: "Current Password",
        change: "Change Password",
        validation: {
          title: "The password must meet the following requirements:",
          length: "At least 8 characters long",
          number: "At least one number",
          specialChar: "At least one special character",
          char: "At least one lowercase/uppercase letter",
          match: "Passwords do not match",
          required: "Required field",
        },
      },
    },
    achievements: {
      name: "Achievements",
      details: {},
    },
    trainingCenter: {
      name: "Training Center",
      details: {},
    },
    docTemplates: {
      name: "Document Templates",
      details: {},
    },
    logout: "Log Out",
  },
  filter: {
    headline: "Filter",
    deleteAll: "Delete All Filters",
    sort: {
      by: "Sort by",
      price: {
        desc: "Highest Price",
        asc: "Lowest Price",
      },
      area: {
        desc: "Largest Plot Area",
        asc: "Smallest Plot Area",
      },
      usableArea: {
        desc: "Largest Usable Area",
        asc: "Smallest Usable Area",
      },
      yearOfConstruction: {
        desc: "Newest Year",
        asc: "Oldest Year",
      },
      returnOfInvestment: {
        desc: "Highest ROI",
        asc: "Lowest ROI",
      },
      onlineSince: {
        desc: "Newest Listing",
        asc: "Oldest Listing",
      },
    },
    min: "Min",
    max: "Max",
    found: {
      object: "Object found | Objects found",
    },
    geschaeftskunden: "Commercial Clients",
  },
};
