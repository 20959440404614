<script>
import SelectUserField from "@/components/SelectUserField.vue";
import ApiService from "@/services/api.service";
import {load} from "youtube-vue3";

export default {
  name: "SelectGKUser",
  methods: {
    load() {
      return load
    }
  },
  components: {SelectUserField},
  data() {
    return {
      loading:false,
      users: []
    }
  },
  computed: {
    userIdR: {
      get() {
        return this.userId;
      },
      set(value) {
        this.$emit("update:userId", value);
      }
    }
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    try {
      this.loading=true;
      const response = await ApiService.get("user/list/role/ROLE_GESCHAEFTSKUNDE");
      this.users = response.data;
    } catch (error) {
      console.error("Failed to load users:", error);
    }finally {
      this.loading=false;
    }
  }
}
</script>

<template>
<SelectUserField placeholder="Geschäftskunde auswählen" :loading="loading" :disabled="disabled" v-model:user-id="userIdR" :users="users"/>
</template>

<style scoped>

</style>