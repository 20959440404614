<script>
import ServerStateIcon from "@/components/navigation/ServerStateIcon.vue";
import ToggleSettingsButton from "@/components/navigation/ToggleSettingsButton.vue";
import LanguageSwitch from "@/components/LanguageSwitch.vue";

export default {
  name: "GkHeader",
  components: {LanguageSwitch, ToggleSettingsButton, ServerStateIcon },
  methods: {
    home() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <v-app-bar class="app-bar bg-primary" elevation="0" app>
    <template v-slot:prepend>
      <v-img
        style="cursor: pointer"
        @click="home"
        :src="require('@/assets/logo.png')"
        cover
        width="80"
        height="40"
      ></v-img>
      <div class="py-2" v-if="$route.meta && $route.meta.appBarBackButton">
        <v-btn icon="mdi-chevron-left" @click="$router.back()"></v-btn>
      </div>
    </template>
    <template v-slot:append>
      <ServerStateIcon />
      <v-dialog class="text-wrap" max-width="500">
        <template #activator="{ props }">
          <v-btn icon v-bind="props"><v-icon>mdi-download</v-icon></v-btn>
        </template>
        <v-card class="pa-5">
          <v-card-title v-text="$t('topBar.saveToHomescreen')"></v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <ul>
              <li>
                {{ $t("topBar.apple") }}
              </li>
              <li>
                {{ $t("topBar.android") }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
      <LanguageSwitch/>
      <ToggleSettingsButton />
    </template>
  </v-app-bar>
</template>

