<template>
<span v-if="numberFormat === ENumberFormat.CURRENCY">
  <CurrencyDisplay :number="betterReadable"/>
</span>
<span v-else-if="numberFormat === ENumberFormat.NUMBER">
  <span v-text="betterReadable"/><span v-text="prefix"></span>
</span>
  <span v-else-if="numberFormat === ENumberFormat.SHORT">
    <span v-text="betterReadableNumberSmall"/><span v-text="prefix"></span>
  </span>
  <span v-else-if="numberFormat === ENumberFormat.PLAIN">
    <span v-text="number"/><span v-text="prefix"></span>
  </span>
<span v-else>
  <span v-text="betterReadable"></span>{{prefix}}
</span>

</template>

<script>
import {betterReadableNumber, shortenNumber} from "@/services/helpers.service";
import ENumberFormat from "@/enums/ENumberFormat";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";

export default {
  name: "ReadableNumber",
  components: {CurrencyDisplay},
  props:{
    prefix:{
      type:String,
      default:null
    },
    number:Number,
    numberFormat:{
      type:ENumberFormat,
      default:ENumberFormat.NONE
    }
  },
  data(){
    return {
      currency: "€"
    }
  },
  computed:{
    ENumberFormat() {
      return ENumberFormat
    },
    betterReadable(){
      if(!this.number) return "-";
      return betterReadableNumber(this.number);
    },
    betterReadableNumberSmall(){
      return shortenNumber(this.number);
    }
  },
}
</script>

<style scoped>

</style>