<template>
  <v-skeleton-loader
    type="list-item-avatar-two-line@5"
    color="transparent"
    :loading="!sections"
  >
    <v-list align="left" v-if="sections.length > 0" style="width: 100%">
      <CourseSectionItem
        v-for="section of sections"
        :section="section"
        @click="$router.push('/admin/weiterbildung/' + section.id)"
        :key="section.id"
      />
    </v-list>
    <v-list align="left" width="100%" v-else>
      <v-list-item
        :title="$t('settingsPopup.admin.trainingCenter.courseSection.noData')"
      ></v-list-item>
    </v-list>
    <v-btn
      block
      class="my-2"
      @click="$router.push('/admin/weiterbildung/section/create')"
      ><v-icon icon="mdi-plus"></v-icon
      >{{
        $t("settingsPopup.admin.trainingCenter.courseSection.addBtn")
      }}</v-btn
    >
  </v-skeleton-loader>
</template>

<script>
import { useCoursesStore } from "@/stores/courses.module";
import CourseSectionItem from "@/components/admin/courses/section/CourseSectionItem";

export default {
  name: "AdminCourseSections",
  components: { CourseSectionItem },
  setup() {
    const coursesStore = useCoursesStore();
    coursesStore.getAllCoursesGroupedBySection();
    return {
      coursesStore,
    };
  },
  computed: {
    sections() {
      return this.coursesStore.sections.populated;
    },
  },
};
</script>

<style scoped></style>
