<template>
  <v-container
    class="d-flex justify-space-between align-center"
    v-if="currentUser"
  >
    <div @click="redirectToProfile" class="text-left">
      <small class="text-disabled">{{ greeting }}</small> <br />
      <span class="text-h5"
        >{{ currentUser.firstName }} {{ currentUser.lastName }}</span
      >
    </div>

    <div class="d-flex align-center">
      <NotificationBell ref="notifications" />
      <CurrentUserAvatar
        @click="redirectToProfile"
        :size="50"
        style="border: 2px solid white"
      ></CurrentUserAvatar>
    </div>
  </v-container>
</template>

<script>
import { useAppStore } from "@/stores/app.module";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { useRewardStore } from "@/stores/reward.module";
import CurrentUserAvatar from "@/components/CurrentUserAvatar";
import NotificationBell from "@/components/notification/NotificationBell";

export default {
  name: "GreetingUser",
  components: { NotificationBell, CurrentUserAvatar },
  setup() {
    const appStore = useAppStore();
    const authStore = useAuthUserStore();
    const rewardStore = useRewardStore();
    rewardStore.getMyRewards();

    return {
      appStore,
      authStore,
      rewardStore,
    };
  },
  computed: {
    currentUser() {
      return this.authStore.user;
    },
    greeting() {
      let moment = require("moment");

      let time = moment().format("HH");
      if (time >= 4 && time < 12) return this.$t("greeting.morning") + ", ";
      else if (time >= 12 && time < 13) return this.$t("greeting.lunch") + ", ";
      else if (time >= 18 && time < 23)
        return this.$t("greeting.evening") + ", ";
      else return this.$t("greeting.default") + ", ";
    },
  },
  methods: {
    redirectToProfile() {
      if (!this.authStore.hasRole("ROLE_GESCHAEFTSKUNDE")) {
        this.$router.push("/profil");
      }
    },
  },
};
</script>
