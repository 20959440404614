<template>
  <v-expansion-panels>
    <v-expansion-panel

    >
      <template #title>
        <v-list-item :title="$t('object.owner.title')">
          <template #subtitle>
            <small v-if="errorMessage" class="text-error">{{errorMessage}}</small>
          </template>
        </v-list-item>
      </template>
      <template #text>
        <v-text-field :disabled="disabledP" v-model="ownerReactive.fullName" :label="$t('object.owner.fullName.title')" class="my-2" :hint="$t('object.owner.fullName.hint')"  :placeholder="$t('object.owner.fullName.title')"></v-text-field>
        <v-text-field :disabled="disabledP" v-model="ownerReactive.email" :label="$t('object.owner.email')" class="my-2"  :placeholder="$t('object.owner.email')"></v-text-field>
       <IntPhoneInput :disabled="disabledP" v-model="ownerReactive.telephone" :label="$t('object.owner.telephone')" class="my-2 "  :placeholder="$t('object.owner.telephone')"/>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import IntPhoneInput from "@/components/IntPhoneInput.vue";

export default {
  name: "EditObjectOwner",
  components: {IntPhoneInput},
  props:{
    owner:Object,
    disabledP:Boolean
  },
  computed:{
    errorMessage(){
      if(!this.ownerReactive.fullName && !this.ownerReactive.email && !this.ownerReactive.telephone) {
        return this.$t('object.owner.error.importantData');
      }
      else if(!this.ownerReactive.fullName) return this.$t('object.owner.error.nameMissing');
      else if(!this.ownerReactive.email) return this.$t('object.owner.error.emailMissing');
      else if(!this.ownerReactive.telephone) return this.$t('object.owner.error.phoneMissing');
      else return null;
    },
    ownerReactive:{
      get(){
        return this.owner;
      },set(value){
        this.$emit("updateOwner",value);
      }
    }
  }
}
</script>

<style scoped>

</style>