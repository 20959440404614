<template>
  <v-card class="pa-4">
    <!-- Hauptinhalt (Timeline) links -->
    <v-card-text class="d-flex flex-column flex-md-row">
      <v-card
        flat
        class="overflow-auto w-100"
        :max-height="$vuetify.display.mdAndUp ? '80vh' : '50vh'"
      >
        <v-skeleton-loader
          v-for="i in 8"
          v-if="loading"
          :key="i"
          class="w-50 my-4 mx-auto text-center"
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-card-text class="overflow-auto" v-else>
          <v-timeline
            truncate-line="both"
            class="me-md-4"
            side="end"
            align="center"
          >
            <GKObjectTimelineItem
              v-for="(item, index) in sortedProgressData"
              :key="item.id"
              :item="item"
              :is-admin="isAdmin"
              :index="index"
              @updatedStep="getCurrentStep"
            />
          </v-timeline>
        </v-card-text>
      </v-card>
      <div
        class="text-center w-100 text-grey-darken-1 mt-md-16"
        v-if="!progressData && !loading"
      >
        Keine Prozessdaten vorhanden
      </div>

      <!-- Divider zwischen Timeline und Sidebar -->
      <v-divider
        class="ma-4"
        thickness="1"
        :vertical="$vuetify.display.mdAndUp"
      ></v-divider>

      <!-- Rechte Sidebar -->
      <v-card
        flat
        min-width="300"
        :max-width="$vuetify.display.mdAndUp ? '350px' : '100%'"
      >
        <v-row>
          <v-col class="v-col-12">
            <v-list-item>
              <template #title>
                <div
                  style="font-size: 1.2rem"
                  class="font-weight-bold mb-2 text-center text-md-left"
                >
                  Details
                </div>
              </template>
            </v-list-item>
            <div class="scrollable">
              <v-list-item
                class="text-left"
                :subtitle="object.title"
                :title="object.objectType.replace('TYPE_', '')"
              >
              </v-list-item>
              <!-- <v-list
                color="transparent"
                rounded
                class="bg-transparent pa-0 my-1"
                max-height="100px"
              >
                <v-list-item
                  disabled
                  v-if="object.todoItemList.length <= 0"
                  :title="$t('noData.small', { obj: 'Aufgaben' })"
                ></v-list-item>
                <v-list-item
                  density="compact"
                  v-for="task in object.todoItemList"
                  :key="task.id"
                  @click="task.done = !task.done"
                >
                  <template v-slot:prepend>
                    <v-checkbox-btn
                      v-model="task.done"
                      color="grey"
                    ></v-checkbox-btn>
                  </template>
                  <v-list-item-title>
                    <span :class="task.done ? 'text-grey' : 'text-grey'">{{
                      task.text
                    }}</span>
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-expand-x-transition>
                      <v-icon v-if="task.done" color="success">
                        mdi-check
                      </v-icon>
                    </v-expand-x-transition>
                  </template>
                </v-list-item>
                
              </v-list> -->
              <v-list-item class="text-left" :title="$t('object.price')">
                <template #subtitle>
                  <ReadableNumber :number="object.value" /> €
                </template>
              </v-list-item>
              <v-list-item class="text-left" :title="$t('object.provision')">
                <template #subtitle>
                  <v-chip density="compact" color="green-lighten-2"><ReadableNumber :number="object.expenctedRefferalFee" :number-format="ENumberFormat.CURRENCY"/> </v-chip>
                </template>

              </v-list-item>
              <v-list-item
                class="text-left"
                :title="$t('object.istFactor')"
                :subtitle="object.returnOfInvestment"
              ></v-list-item>
              <v-list-item
                class="text-left"
                :title="$t('object.usableArea')"
                :subtitle="
                  (object.usableArea ? object.usableArea : '-') + ' m²'
                "
              ></v-list-item>
              <v-list-item
                class="text-left"
                :title="$t('object.baseArea')"
                :subtitle="(object.landArea ? object.landArea : '-') + ' m²'"
              ></v-list-item>
              <v-list-item
                class="text-left"
                :title="$t('object.yearOfConstruction')"
              >
                <template #subtitle>
                  <span v-if="!object.yearOfConstruction">-</span>
                  <span v-else v-text="object.yearOfConstruction"></span>
                </template>
              </v-list-item>
              <v-list-item class="text-left" :title="$t('object.onlineSince')">
                <template #subtitle>
                  <DateDisplay :date="object.createdAt" />
                </template>
              </v-list-item>
            </div>
          </v-col>
          <v-col class="v-col-12">
            <v-list-item>
              <template #title>
                <div
                  style="font-size: 1.1rem"
                  class="font-weight-bold mb-4 text-center text-md-left"
                >
                  {{ $t("object.contacts") }}
                </div>
              </template>
            </v-list-item>
            <v-row no-gutters>
              <v-col cols="2">
                <UserAvatar
                  tile
                  :size="65"
                  class="ml-4"
                  :user="object.contact"
                />
              </v-col>
              <v-col cols="10" class="pl-6">
                <v-list-item class="text-left" :title="object.contact.fullName">
                  <template #subtitle>
                    <p>
                      <v-icon class="pa-0 mr-1" icon="mdi-mail"></v-icon>
                      <a
                        :href="'mailto:' + object.contact.email"
                        class="text-white"
                        style="cursor: pointer; font-size: 0.7rem"
                        >{{ object.contact.email }}</a
                      >
                    </p>
                    <p>
                      <v-icon class="pa-0 mr-1" icon="mdi-phone"></v-icon>
                      <a
                        :href="'tel:' + object.contact.mobileNumber"
                        class="text-white"
                        style="cursor: pointer; font-size: 0.7rem"
                        >{{ object.contact.mobileNumber }}</a
                      >
                    </p>
                  </template>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar.vue";
import DateDisplay from "@/components/helpers/DateDisplay.vue";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import ApiService from "@/services/api.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import GKObjectTimelineItem from "./GKObjectTimelineItem.vue";
import ENumberFormat from "@/enums/ENumberFormat";

export default {
  name: "GKObjectItemDetails",
  components: { UserAvatar, DateDisplay, ReadableNumber, GKObjectTimelineItem },
  setup() {
    const authUserStore = useAuthUserStore();
    return { authUserStore };
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ENumberFormat() {
      return ENumberFormat
    },
    isAdmin() {
      return this.authUserStore.isAdmin;
    },
    sortedProgressData() {
      return [...this.progressData].sort((a, b) => a.stepIndex - b.stepIndex);
    },
  },
  data() {
    return {
      progressData: null,
      loading: false,
    };
  },
  methods: {
    getCurrentStep() {
      let step = this.progressData
        .filter((item) => item.state === "IN_PROGRESS")
        .sort((a, b) => b.stepIndex - a.stepIndex)[0];
      console.log("Step", step);
      this.$emit("updatedStep", step);
    },
    async fetchObjectProgress() {
      this.loading = true;
      try {
        const response = await ApiService.get(
          `gkProgress/object/${this.object.id}`
        );
        if (response && response.data) {
          this.progressData = response.data;
        }
      } catch (error) {
        console.error("Fehler beim Laden der Fortschrittsdaten:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.fetchObjectProgress();
    console.log("Object", this.object);
  },
};
</script>
