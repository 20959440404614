<template>
  <div class="my-10">
    <!--<v-checkbox label="Kunde hat gezahlt."/>-->
    <v-btn
      :loading="loading"
      v-if="!sale.paymentReceived"
      @click="saveSale(true)"
      block
      color="accent"
      >{{ $t("settingsPopup.admin.objects.edit.payment.saveBtn") }}</v-btn
    >
    <small v-if="!sale.paymentReceived">{{
      $t("settingsPopup.admin.objects.edit.payment.infoText1")
    }}</small>
    <span v-else>{{
      $t("settingsPopup.admin.objects.edit.payment.infoText2")
    }}</span>
  </div>
</template>

<script>
import { changePaymentStatus } from "@/services/admin.service";

export default {
  name: "EditVerkauf",
  props: {
    sale: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async saveSale(value) {
      this.loading = true;
      let sale = await changePaymentStatus(this.sale.objectId, value);
      this.$emit("update", sale);
      this.loading = false;

      location.href = "/admin/objekt/" + this.sale.objectId;
    },
  },
};
</script>

<style scoped></style>
