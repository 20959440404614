import {de} from '@/plugins/l18i/languages/de'
import {gb} from "@/plugins/l18i/languages/gb";
import {it} from "@/plugins/l18i/languages/it";
import {tr} from "@/plugins/l18i/languages/tr";

export const messages = {
      de:de,
      gb:gb,
      it:it,
      tr:tr

}
