import { tr as vtr } from "vuetify/locale";

export const tr = {
  $vuetify: {
    ...vtr,
  },
  enum: {
    pending: "Beklemede",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Tanıtım/Sunum gönder",
      AWAIT_APPROVAL: "Onay bekle",
      ADD_PROPERTY_IN_ESTATEBOOST: "EstateBoost'ta mülk ekle",
      VERIFY_INPUT: "Girişi doğrula",
      CREATE_EXPOSE_DATA_ROOM: "Sunum/Veri odası oluştur",
      EVALUATE_PROPERTY: "Mülk değerlendirmesi",
      APPROVE_EVALUATION: "Değerlendirmeyi onayla",
      OPEN_DUE_DILIGENCE_PROCESS: "Due Diligence sürecini başlat",
      INITIATE_MARKET_CONTACT: "Pazar temasını başlat",
      MAKE_BINDING_OFFER: "Bağlayıcı teklif ver",
      COMPLETE_NOTARIZATION: "Noter işlemlerini tamamla",
      RECEIVE_EXTERNAL_COMMISSION: "Dış komisyonu al",
      PAY_TIP_PROVIDER_COMMISSION: "İpucu veren komisyonunu öde",
      ANALYZE_PROPERTY_DATA: "Mülk verilerini analiz et",
      CONDUCT_MARKET_ANALYSIS: "Pazar analizi yap",
      SELECT_EVALUATION_METHOD: "Değerlendirme yöntemi seç",
      INTERNAL_VALIDATION: "İç doğrulama",
      APPROVE_COMMUNICATION: "İletişimi onayla",
      PREPARE_NEGOTIATION_STRATEGY: "Müzakere stratejisi hazırla",
      START_NEGOTIATIONS: "Müzakereleri başlat",
      CONDUCT_NEGOTIATIONS: "Müzakereleri yürüt",
      ADJUST_CONTRACT_TERMS: "Sözleşme şartlarını düzenle",
      FINALIZE_AND_SIGN_CONTRACT: "Sözleşmeyi sonlandır ve imzala",
    },
    gkProcessState: {
      OPEN: "Açık",
      IN_PROGRESS: "İşlemde",
      DONE: "Tamamlandı",
    },
  },
  tasks: "Görev | Görevler",
  notification: {
    title: "Bildirimler",
    deleteAll: "Hepsini sil",
    noData: "Bildirim yok",
  },
  wallet: {
    token: "Token | Tokenlar",
    coin: "Coin | Coinler",
  },
  education: {
    to: "Eğitime Git",
  },
  files: {
    download: "Dosyayı indir",
    upload: "Yeni dosya yükle",
  },
  process: {
    coinFormula:
      "Bu değer, bir mülk sahibine verdiğiniz ipucu sayesinde onunla bir komisyonculuk sözleşmesi kurulmasından dolayı alacağınız ücreti temsil eder. Bu ücret, satış fiyatına bağlı olan komisyon üzerinden hesaplanır. Satıcının komisyon payındaki (iç komisyon) hisseniz şöyledir:",
    ownerConfirmation: {
      title: "Mal Sahibi Onay Beyanı",
      text: "Mal sahibinin onayı, sürecin en önemli parçasıdır.<br/>EstateBoost, <b>şeffaflık</b> ve <b>dürüstlük</b> ilkesini benimser ve yalnızca <b>gönüllü</b> ve <b>mal sahibi tarafından bilerek sağlanan</b> verileri toplar.",
      alert:
        "Özellikle vurguluyoruz ki, şirket olarak yasal olarak paylaşılması mümkün olmayan verilerle ilgilenmiyoruz.*",
      footNote:
        "*Herhangi bir kötüye kullanım hesap askıya alma ile sonuçlanır.",
      confirm:
        "Bu platformda mal sahibinin kişisel verilerini paylaşmak için kendisinden onay aldığımı burada teyit ediyorum.",
      download: "PDF şablonunu buradan indir",
      upload: "Yeni onay beyanı yükle",
      noConfirmation: "Yüklenmiş bir onay beyanı yok",
      noConfirmation2: "Onay verilmedi.",
    },
    objectData: {
      title: "Nesne Verilerini Gir",
    },
    verification: {
      title: "Doğrulama",
      text: "Nesneniz artık doğrulamaya hazır.<br/>Lütfen girdiklerinizi tekrar gözden geçirin.",
      warning:
        "Doğrulamayı gönderdikten sonra nesne verilerini daha fazla düzenleyemezsiniz.",
      button: "Şimdi doğrula",
      running:
        '<p>Doğrulama devam ediyor. Mal sahibine ulaştığımızda ve verilerinizi doğruladığımızda sizi bilgilendireceğiz.</p><p>Güncel durum:<br/> <small class="text-accent" v-html="state.message"></small></p>',
    },
    contract: {
      title: "Komisyon Sözleşmesi Tamamlandı",
      text: 'Mal sahibi ile yapılan komisyon sözleşmesi <span class="success">aktif</span>.<br/><v-chip >{date}</v-chip> tarihine kadar "sizin" mülkünüzü pazarlama süremiz var.',
    },
    marketing: {
      title: "Pazarlama",
    },
    notaryAppointment: {
      title: "Noter Randevusu",
    },
    provision: {
      title: "Komisyon Alındı",
    },
    collect: {
      title: "Coinleri Topla",
      text: "Artık coinlerinizi toplayabilirsiniz! Ayrıca coinlerinizi Euro'ya çevirerek kayıtlı hesabınıza çekebilirsiniz.",
      alreadyCollected: "Coinlerinizi zaten topladınız.",
    },
    overallProgress: "Genel İlerleme",
    collectCoins: "Coinleri Şimdi Topla",
    success: "Başarılı",
    reward: "Coin Ödülü",
  },
  topBar: {
    saveToHomescreen: "Web Uygulamasını ana ekranınıza kaydedin!",
    apple:
      "iPhone/iPad: Paylaş düğmesine dokunun ve 'Ana Ekrana Ekle' seçeneğini seçin.",
    android: "Üç noktaya dokunun ve 'Ana Ekrana Ekle'yi seçin.",
  },
  greeting: {
    morning: "Günaydın",
    lunch: "Öğle Yemeği Zamanı",
    evening: "İyi Akşamlar",
    default: "İyi Günler",
  },
  buttons: {
    buy: "Satın Al",
    close: "Kapat",
    save: "Kaydet",
    send: "Gönder",
    cancel: "İptal",
    open: "Aç",
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Banka Adı",
    accountHolder: "Hesap Sahibi",
  },
  user: {
    level: "Seviye",
    profile: {
      tipster: "İpucu Veren",
      checkEducation:
        "*Bir mülk eklemek için gizlilik eğitimimizi tamamlamalısınız.",
    },
    registeredAt: "Üyelik tarihi",
    firstName: "Ad",
    lastName: "Soyad",
    email: "E-posta",
    phone: "Telefon Numarası",
    mobile: "Cep Telefonu",
    bio: "Biyografi",
    birthDate: "Doğum Tarihi",
    messages: {
      emailVerified: "E-posta adresiniz doğrulandı.",
      emailNotVerified:
        "Uygulamayı tam olarak kullanmak için e-postanızı doğrulamalısınız.<br/><b>E-postayı Şimdi Doğrula</b>ya tıklayın, size bir e-posta göndereceğiz.",
      validateBtn: "E-postayı Şimdi Doğrula.",
      changeBirthDate: "Doğum Tarihini Güncelle",
    },
  },
  address: {
    title: "Adresler",
    showOnMap: "Haritada göster",
    save: "Yeni adres ekle",
    street: "Sokak",
    houseNumber: "Bina Numarası",
    zip: "Posta Kodu",
    city: "Şehir",
    bundesland: "Eyalet",
    country: "Ülke",
  },
  yourObjects: "Mülkleriniz",
  allObjects: "Tüm Mülkler",
  form: {
    search: "Ara",
    invalidDate: "Geçersiz Tarih",
  },
  noData: {
    default: "Veri bulunamadı",
    reactive: "Hiç {obj} bulunamadı",
    small: "Hiç {obj} yok",
  },
  object: {
    owner: {
      title: "Mülk Sahibi",
      fullName: {
        title: "Mal Sahibinin Tam Adı",
        hint: "Lütfen bu verileri mal sahibinin onayı olmadan paylaşmanın yasak olduğunu unutmayın.",
      },
      email: "Mal Sahibinin E-postası",
      telephone: "Mal Sahibinin Telefon Numarası",
      error: {
        importantData: "Önemli veriler eksik!",
        nameMissing: "Mal sahibinin adı eksik.",
        emailMissing: "Mal sahibinin e-postası eksik.",
        phoneMissing: "Mal sahibinin telefon numarası eksik.",
      },
    },
    sale: "SATIŞ",
    retirement: "EMEKLİLİK",
    slots: {
      locked: "Kilitli Slot",
      unlock: "Slotu Aç",
      buy: {
        confirm: "OK'e tıklayarak {token} token düşülecek ve 1 slot eklenecek.",
        title: "Daha fazla mülk eklemek için yeni bir slot satın alın.",
        insufficientSales:
          "Yeni slotları açmak için en az 2 mülk satmalısınız.",
        commercialActivity:
          "Yılda üçüncü mülkten itibaren ticari faaliyet sayılır. Lütfen gerekirse bir işletme kaydı yapmanız gerekebileceğini unutmayın.",
        priceCalculation:
          "Fiyat, son tamamladığınız ipuçlarına göre hesaplanır.",
      },
    },
    saled: "satıldı",
    object: "Nesne | Nesneler",
    estate: "Mülk | Mülkler",
    price: "Fiyat",
    istFactor: "Verim Faktörü / Gerçek Getiri",
    usableArea: "Kullanılabilir Alan",
    flatArea: "Yaşam Alanı",
    baseArea: "Arsa Alanı",
    tooltip: "Tahmini Komisyon",
    yearOfConstruction: "Yapım Yılı",
    onlineSince: "Çevrimiçi Olduğu Tarih",
    contacts: "İrtibat Kişisi",
    add: "Mülk Ekle",
    expected: "tahmini",
    actual: "gerçek",
    fairValue: "Gerçek Piyasa Değeri",
    fairValueExpected: "Tahmini Piyasa Değeri",
    fairValueExpectedExplanation:
      "Tahmini piyasa değeri, çeşitli faktörlere göre hesaplanır ve satış fiyatından farklı olabilir.",
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Yönetim Alanı",
      objects: {
        title: "Mülkler",
        subtitle: "Mülklerinizi yönetin",
        verification: {
          title: "Doğrulama",
          whyError: "Doğrulama neden başarısız oldu?",
          whyErrorPlaceholder: "Nedeni buraya yazın...",
          whyErrorLabel: "Başarısızlık Nedeni",
          send: "Gönder",
          cancel: "İptal",
          declarationOfConsent: "Mal Sahibi Onay Beyanı",
          sellType: "İlgilendiği Konu",
          objectType: "Mülk Türü",
          livingArea: "Yaşam Alanı",
          baseArea: "Arsa Alanı",
          owner: "Mal Sahibi",
          createdBy: "Oluşturan: ",
        },
        marketing: {
          title: "Pazarlama",
          edit: "Düzenle",
          view: "Görüntüle",
        },
        success: "Başarılı",
        error: "Hata",
        edit: {
          brokerCommissionGk: {
            title: "Broker Komisyonu GK",
            fairValue: "Sözleşme Öncesi Piyasa Değeri",
            salesValue: "Satış Değeri",
            provision: "Komisyon %",
            tipgiverProvision: "İpucu Veren Komisyonu %",
            brokerCommission: "Broker Komisyonu %",
            closeBtn: "Kapat",
          },
          userView: "Kullanıcı Görünümü",
          overview: {
            title: "Genel Bakış",
          },
          agentContract: {
            title: "Broker Sözleşmesi",
            dateEnd: "Sözleşme Bitiş Tarihi",
            signedCheckbox: "Sözleşme İmzalı",
            loadingBtn: "Sözleşme Nesnesi Oluştur",
            saveBtn: "Kaydet",
          },
          marketing: {
            title: "Pazarlama",
            date: "Noter Randevusu: ",
            successCheckbox: "Mülk Pazarlanmış",
            saveBtn: "Kaydet",
            loadingBtn: "Noter Randevusu Nesnesi Oluştur",
          },
          payment: {
            title: "Ödeme",
            saveBtn: "Mülkü Kullanıcı İçin Tamamla",
            infoText1: "*Kullanıcı şimdi coinlerini toplayabilir",
            infoText2: "Kullanıcı şimdi coinlerini çekebilir",
          },
          fairValue: "Piyasa Değeri",
          provision: "Komisyon",
          tipGiver: "İpucu Veren",
        },
      },
      trainingCenter: {
        title: "Eğitim Merkezi",
        subtitle: "Eğitimleri Düzenle ve Ekle",
        courseSection: {
          noData: "Hiç bölüm bulunamadı",
          addBtn: "Ekle",
          addTitle: "Başlık",
          addDescription: "Açıklama",
          addChapter: "Bölüm",
          addSaveBtn: "Yeni Kurs Yükle",
        },
      },
      payout: {
        title: "Ödemeler",
        subtitle: "Tüm Ödemeler",
      },
      statistics: {
        title: "İstatistikler",
        subtitle: "İstatistikler",
      },
    },
    accountDetails: {
      name: "Hesap Detayları",
      details: {},
    },
    address: {
      name: "Adresler",
      details: {},
    },
    bankDetails: {
      name: "Banka Bilgileri",
      details: {},
    },
    shareProfile: {
      name: "Profili Paylaş",
      details: {},
    },
    settings: {
      name: "Ayarlar",
      details: {},
    },
    changePassword: {
      name: "Şifre Değiştir",
      details: {
        newPassword: "Yeni Şifre",
        confirmPassword: "Şifreyi Onayla",
        oldPassword: "Mevcut Şifre",
        change: "Şifreyi Değiştir",
        validation: {
          title: "Şifre şu gereksinimleri karşılamalı:",
          length: "En az 8 karakter",
          number: "En az bir rakam",
          specialChar: "En az bir özel karakter",
          char: "En az bir büyük/küçük harf",
          match: "Şifreler eşleşmiyor",
          required: "Zorunlu alan",
        },
      },
    },
    achievements: {
      name: "Başarılar",
      details: {},
    },
    trainingCenter: {
      name: "Eğitim Merkezi",
      details: {},
    },
    docTemplates: {
      name: "Belge Şablonları",
      details: {},
    },
    logout: "Çıkış Yap",
  },
  filter: {
    headline: "Filtre",
    deleteAll: "Tüm Filtreleri Sil",
    sort: {
      by: "Sırala",
      price: {
        desc: "En Yüksek Fiyat",
        asc: "En Düşük Fiyat",
      },
      area: {
        desc: "En Büyük Arsa Alanı",
        asc: "En Küçük Arsa Alanı",
      },
      usableArea: {
        desc: "En Büyük Kullanılabilir Alan",
        asc: "En Küçük Kullanılabilir Alan",
      },
      yearOfConstruction: {
        desc: "En Yeni Yapım Yılı",
        asc: "En Eski Yapım Yılı",
      },
      returnOfInvestment: {
        desc: "En Yüksek ROI",
        asc: "En Düşük ROI",
      },
      onlineSince: {
        desc: "En Yeni İlan",
        asc: "En Eski İlan",
      },
    },
    min: "Min",
    max: "Maks",
    found: {
      object: "Obje(ler) bulundu",
    },
    geschaeftskunden: "Ticari müşteri",
  },
};
