<script>
import {useAuthUserStore} from "@/stores/modules/auth.module";
import GkHeader from "@/views/geschaeftskunde/GkHeader.vue";

export default {
  name: "gkDashboardWrapper",
  components: {GkHeader},
  setup () {
    const authUserStore = useAuthUserStore()
    return {
      authUserStore
    }
  }
}
</script>

<template>
 <GkHeader/>
  <router-view/>
</template>

<style scoped>

</style>