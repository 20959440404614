import { de as vde } from "vuetify/locale";

export const de = {
  $vuetify: {
    ...vde,
  },
  enum: {
    pending: "Ausstehend",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Teaser/Expose senden",
      AWAIT_APPROVAL: "Auf Freigabe warten",
      ADD_PROPERTY_IN_ESTATEBOOST: "Immobilie in EstateBoost anlegen",
      VERIFY_INPUT: "Eingabe verifizieren",
      CREATE_EXPOSE_DATA_ROOM: "Expose/Datenraum erstellen",
      EVALUATE_PROPERTY: "Einwertung der Immobilie",
      APPROVE_EVALUATION: "Einwertung freigeben",
      OPEN_DUE_DILIGENCE_PROCESS: "Due Diligence Prozess eröffnen",
      INITIATE_MARKET_CONTACT: "Marktansprache durch Matching",
      MAKE_BINDING_OFFER: "Verbindliche Angebotsabgabe",
      COMPLETE_NOTARIZATION: "Notarielle Beurkundung abschließen",
      RECEIVE_EXTERNAL_COMMISSION: "Eingang der Außenprovision",
      PAY_TIP_PROVIDER_COMMISSION: "Tippgeberprovision auszahlen",
      ANALYZE_PROPERTY_DATA: "Objektdaten erheben",
      CONDUCT_MARKET_ANALYSIS: "Marktanalyse durchführen",
      SELECT_EVALUATION_METHOD: "Bewertungsansatz auswählen",
      INTERNAL_VALIDATION: "Interne Validierung",
      APPROVE_COMMUNICATION: "Freigabe und Kommunikation",
      PREPARE_NEGOTIATION_STRATEGY: "Verhandlungsstrategie vorbereiten",
      START_NEGOTIATIONS: "Verhandlungen einleiten",
      CONDUCT_NEGOTIATIONS: "Verhandlungen durchführen",
      ADJUST_CONTRACT_TERMS: "Vertragsbedingungen anpassen",
      FINALIZE_AND_SIGN_CONTRACT: "Vertragsabschluss finalisieren",
    },
    gkProcessState: {
      OPEN: "Offen",
      IN_PROGRESS: "in Bearbeitung",
      DONE: "Abgeschlossen",
    },
  },
  tasks: "Aufgabe | Aufgaben",
  notification: {
    title: "Benachrichtigungen",
    deleteAll: "Alle löschen",
    noData: "Keine Benachrichtigungen",
  },
  wallet: {
    token: "Token | Token",
    coin: "Coin | Coins",
  },
  education: {
    to: "Zur Weiterbildung",
  },
  files: {
    download: "Datei herunterladen",
    upload: "Neue Datei hochladen",
  },
  process: {
    coinFormula:
      "Dieser Wert stellt deine Vergütung für das Zustandekommen eines Maklervertrages mit dem Eigentümer der Immobilie durch deinen Tipp an uns dar. Dieser berechnet sich aus der daraus entstehenden Maklerprovision, welche vom Verkaufspreis abhängig ist. Dein Anteil an der Provision des Verkäufers (Innenprovision) beträgt",
    ownerConfirmation: {
      title: "Einverständniserklärung des Eigentümers",
      text: "Die Einverständniserklärung ist das wichtigste Element des Prozesses.<br/>EstateBoost steht für <b>Transparenz</b> und <b>Ehrlichkeit</b> und will ausdrücklich nur Daten sammeln, welche <b>freiwillig</b> und mit <b>voller Absicht vom Eigentümer bereit gestellt werden</b>.",
      alert:
        "Wir betonen ausdrücklich, dass wir als Unternehmen keine Interesse an Daten haben, welche rechtlich nicht an uns weitergegeben werden dürfen.*",
      footNote: "*Jeglicher Missbrauch führt zur Sperrung des Kontos.",
      confirm:
        "Hiermit bestätige ich, dass mir die Einwilligung des Eigentümers der Immobilie vorliegt, um seine personenbezogenen Daten auf unserer Plattform angeben zu dürfen.",
      download: "PDF Vorlage hier downloaden",
      upload: "Neue Einverständniserklärung hochladen",
      noConfirmation: "Keine Einverständniserklärung hochgeladen",
      noConfirmation2: "Keine Einverständnis gegeben.",
    },
    objectData: {
      title: "Objektdaten einpflegen",
    },
    verification: {
      title: "Verifizierung",
      text: "Dein Objekt ist nun bereit für die Verifizierung.<br/>Bitte prüfe noch einmal deine Eingaben.",
      warning:
        "Sobald Sie die Verifizierung abschicken, können Sie die Objektdaten nichtmehr bearbeiten.",
      button: "Jetzt verifizieren",
      running:
        '<p>Die Verifizierung läuft.  Wir werden dich benachrichtigen, sobald wir den Eigentümer erreicht haben & deine Eingaben verifiziert sind.</p><p>Aktueller Status:<br/> <small class="text-accent" v-html="state.message"></small></p>',
    },
    contract: {
      title: "Marklervertrag abgeschlossen",
      text: 'Der Maklervertrag mit dem Besitzer ist <span class="success">aktiv</span>.<br/>Wir haben jetzt bis zum <v-chip >{date}</v-chip> Zeit, um "deine" Immobilie zu vermarkten.',
    },
    marketing: {
      title: "Vermarktung",
    },
    notaryAppointment: {
      title: "Notartermin",
    },
    provision: {
      title: "Maklerprovision erhalten",
    },
    collect: {
      title: "Coins einsammeln",
      text: "Du kannst deine Coins nun einsammeln! Außerdem kannst du dir jetzt auch die Coins in Euro auf dein angegebenes Konto auszahlen lassen.",
      alreadyCollected: "Du hast deine Coins bereits eingesammelt.",
    },
    overallProgress: "Gesamtfortschritt",
    collectCoins: "Coins jetzt einsammeln",
    success: "Erfolg",
    reward: "Coins Gewinn",
  },
  topBar: {
    saveToHomescreen: "Speichere die WebApp auf deinem Homescreen!",
    apple:
      "iPhone/iPad: Tippe auf den Teilen-Button und wähle „Zum Home-Bildschirm hinzufügen“.",
    android:
      "Tippe auf die drei Punkte und wähle „Zum Startbildschirm hinzufügen“.",
  },
  greeting: {
    morning: "Guten Morgen",
    lunch: "Mahlzeit",
    evening: "Guten Abend",
    default: "Guten Tag",
  },
  buttons: {
    buy: "Kaufen",
    close: "Schließen",
    save: "Speichern",
    send: "Senden",
    cancel: "Abbrechen",
    open: "Öffnen",
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Bankname",
    accountHolder: "Kontoinhaber",
  },
  user: {
    level: "Level",
    profile: {
      tipster: "Tippgeber",
      checkEducation:
        "*um eine Immobilie anzulegen, muss unsere Datenschutz Weiterbildung abgeschlossen werden.",
    },
    registeredAt: "Mitglied seit",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "Email",
    phone: "Telefonnummer",
    mobile: "Mobilnummer",
    bio: "Biografie",
    birthDate: "Geburtsdatum",
    messages: {
      emailVerified: "Deine Emailadresse ist bestätigt.",
      emailNotVerified:
        "Um die App voll nutzen zu können, musst du deine Email bestätigen. <br/>Gehe auf <b>Email jetzt bestätigen</b> & wir schicken dir eine Email an deine angegebene Email.",
      validateBtn: "Email jetzt bestätigen.",
      changeBirthDate: "Geburtsdatum aktualisieren",
    },
  },
  address: {
    title: "Adressen",
    showOnMap: "Auf Karte anzeigen",
    save: "Neue Addresse anlegen",
    street: "Straße",
    houseNumber: "Hausnummer",
    zip: "PLZ",
    city: "Stadt",
    bundesland: "Bundesland",
    country: "Land",
  },
  yourObjects: "Ihre Objekte",
  allObjects: "Alle Objekte",
  form: {
    search: "Suchen",
    invalidDate: "Ungültiges Datum",
  },
  noData: {
    default: "Keine Daten gefunden",
    reactive: "Keine {obj} gefunden",
    small: "Keine {obj}",
  },
  object: {
    owner: {
      title: "Eigentümer des Objekts",
      fullName: {
        title: "Voller Name des EIgentümers",
        hint: "Bitte denke daran, dass es nicht gestattet ist, diese Daten ohne die Einwilligung des Eigentümers anzugeben.",
      },
      email: "Email des Eigentümers",
      telephone: "Telefonnummer des Eigentümers",
      error: {
        importantData: "Es fehlen wichtige Daten!",
        nameMissing: "Der Name des Eigentümers fehlt.",
        emailMissing: "Die Email des Eigentümers fehlt.",
        phoneMissing: "Die Telefonnummer des Eigentümers fehlt.",
      },
    },
    sale: "VERKAUF",
    retirement: "VERRENTUNG",
    slots: {
      locked: "Gesperrter Slot",
      unlock: "Slot freischalten",
      buy: {
        confirm:
          "Mit Klick auf OK werden dir {token} Token abgezogen & 1 Slot hinzugefügt",
        title: "Kaufe einen neuen Slot, um weitere Immobilien hinzuzufügen.",
        insufficientSales:
          "Du musst mindestens 2 Objekte verkauft haben, um neue Slots freizuschalten.",
        commercialActivity:
          "Ab der dritten Immobilie im Jahr wird von einer gewerblichen Tätigkeit ausgegangen. Bitte beachten Sie, dass Sie gegebenenfalls ein Gewerbe anmelden müssen.",
        priceCalculation:
          "Der Preis erreichnet sich aus deinen zuletzt abgeschlossenen Tipps.",
      },
    },
    saled: "verkauft",
    object: "Objekt | Objekte",
    estate: "Immobilie | Immobilien",
    price: "Preis",
    istFactor: "Ist-Faktor / Ist-Rendite",
    usableArea: "Nutzfläche",
    flatArea: "Wohnfläche",
    baseArea: "Grundfläche",
    tooltip: "geschätzte Vermittlerprovision",
    yearOfConstruction: "Baujahr",
    onlineSince: "Online seit",
    contacts: "Ansprechpartner",
    addNote: "Notiz hinzufügen...",
    noNote: "Keine Notiz",
    add: "Immobilie anlegen",
    expected: "geschätzt",
    actual: "tatsächlich",
    provision:"Provision",
    fairValue: "Marktwert",
    fairValueExpected: "geschätzter Marktwert",
    fairValueExpectedExplanation:
      "Der geschätzte Marktwert wird anhand von verschiedenen Faktoren berechnet & kann vom Verkaufspreis abweichen.",
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Adminbereich",
      objects: {
        title: "Objekte",
        subtitle: "Verwalten Sie Ihre Objekte",
        verification: {
          title: "Verifizierung",
          whyError: "Warum ist die Verifizierung fehlgeschlagen?",
          whyErrorPlaceholder: "Grund hier...",
          whyErrorLabel: "Grund für Fehlschlag",
          send: "Senden",
          cancel: "Abbrechen",
          declarationOfConsent: "Einverständniserklärung des Eigentümers",
          sellType: "Interesse an",
          objectType: "Objektart",
          livingArea: "Wohnfläche",
          baseArea: "Grundfläche",
          owner: "Eigentümer",
          createdBy: "erstellt von: ",
        },
        marketing: {
          title: "Marketing",
          edit: "Bearbeiten",
          view: "Ansehen",
        },
        success: "Erfolg",
        error: "Fehler",
        edit: {
          brokerCommissionGk: {
            title: "Vermittlerprovision GK",
            fairValue: "Marktwert vor Vertrag",
            salesValue: "Verkaufswert",
            provision: "Provision in %",
            tipgiverProvision: "Tippgeber Provision in %",
            brokerCommission: "Vermittlerprovision in %",
            brokerCommissionHint:"<p>Der Wert der Vermittlerprovision, die dem Kunden angezeigt wird, ergibt sich aus dem <i>Verkaufspreis</i>, der <i>Provision</i> & der <i>Vermittlerprovision</i>.</p><p> Formel: <b>Marktwert * (Provision/100) * (Vermittlerprovision / 100) = <strong>{calculated}€</strong></b></p>" +
                "<p>Der Marktwert ist dabei der <i>Marktwerk vor Verkauf</i>, sobald der Verkaufswert gesetzt wird, wird die Provision auf dessen Basis kalkuliert</p>",
            closeBtn: "Schließen",
          },
          userView: "Benutzeransicht",
          overview: {
            title: "Überblick",
          },
          agentContract: {
            title: "Maklervertrag",
            dateEnd: "Vertragsende",
            signedCheckbox: "Vertrag ist unterschrieben",
            loadingBtn: "Erstelle Vertragsobjekt",
            saveBtn: "Speichern",
          },
          marketing: {
            title: "Vermarktung",
            date: "Notartermin am: ",
            successCheckbox: "Immobilie vermarktet",
            saveBtn: "Speichern",
            loadingBtn: "Erstelle Notartermin Objekt",
          },
          payment: {
            title: "Zahlung",
            saveBtn: "Immobilie für User abschließen",
            infoText1: "*User kann damit seine Coins einsammeln",
            infoText2: "User kann nun seine Coins abholen",
          },
          fairValue: "Marktwert",
          provision: "Provision",
          tipGiver: "Tippgeber",
        },
      },
      trainingCenter: {
        title: "Weiterbildungszentrale",
        subtitle: "Weiterbildungen bearbeiten und hinzufügen",
        courseSection: {
          noData: "Keine Sektionen gefunden",
          addBtn: "Hinzufügen",
          addTitle: "Titel",
          addDescription: "Beschreibung",
          addChapter: "Kapitel",
          addSaveBtn: "Neuen Kurs hochladen",
        },
      },
      payout: {
        title: "Auszahlungen",
        subtitle: "Alle Auszahlungen",
      },
      statistics: {
        title: "Statistik",
        subtitle: "Statistik",
      },
    },
    accountDetails: {
      name: "Accountdaten",
      details: {},
    },
    address: {
      name: "Adressen",
      details: {},
    },
    bankDetails: {
      name: "Bankverbindung",
      details: {},
    },
    shareProfile: {
      name: "Profil teilen",
      details: {},
    },
    settings: {
      name: "Einstellungen",
      details: {},
    },
    changePassword: {
      name: "Passwort ändern",
      details: {
        newPassword: "Neues Passwort",
        confirmPassword: "Passwort bestätigen",
        oldPassword: "Aktuelles Passwort",
        change: "Passwort ändern",
        validation: {
          title: "Das Passwort muss folgende Anforderungen erfüllen:",
          length: "Mindestens 8 Zeichen lang sein",
          number: "Mindestens eine Zahl enthalten",
          specialChar: "Mindestens ein Sonderzeichen enthalten",
          char: "Mindestens ein Klein-/ Großbuchstaben enthalten",
          match: "Passwörter stimmen nicht überein",
          required: "Pflichtfeld",
        },
      },
    },
    achievements: {
      name: "Achievements",
      details: {},
    },
    trainingCenter: {
      name: "Weiterbildungszentrale",
      details: {},
    },
    docTemplates: {
      name: "Dokumentenvorlagen",
      details: {},
    },
    logout: "Abmelden",
  },
  filter: {
    headline: "Filter",
    deleteAll: "Alle Filter löschen",
    sort: {
      by: "Sortieren nach",
      price: {
        desc: "höchster Preis",
        asc: "niedrigster Preis",
      },
      area: {
        desc: "größte Grundfläche",
        asc: "kleinste Grundfläche",
      },
      usableArea: {
        desc: "größte Nutzfläche",
        asc: "kleinste Nutzfläche",
      },
      yearOfConstruction: {
        desc: "neuestes Baujahr",
        asc: "ältestes Baujahr",
      },
      returnOfInvestment: {
        desc: "höchster ROI",
        asc: "niedrigster ROI",
      },
      onlineSince: {
        desc: "neueste Veröffentlichung",
        asc: "älteste Veröffentlichung",
      },
    },
    min: "Min",
    max: "Max",
    found: {
      object: "Objekt gefunden | Objekte gefunden",
    },
    geschaeftskunden: "Geschäftskunden",
  },
};
