<template>
  <v-timeline-item
    :icon="getStateIcon"
    :dot-color="getStateColor"
    :icon-color="getIconColor"
    fill-dot
  >
    <v-card :color="getStateColor" variant="outlined" class="pa-1">
      <v-card-title class="text-subtitle-1 d-flex align-center flex-wrap">
        <!-- <EnumDisplay
          class="text-wrap me-sm-16"
          :enum-object="EGkProgressStep"
          :enum-key="item.progressStep"
        /> -->
        <EnumDisplayLanguage
          class="text-wrap me-sm-16"
          enum-name="gkProcess"
          :enum-item="item.progressStep"
        />

        <v-chip size="small" :color="getStateColor">
          <EnumDisplayLanguage
            enum-name="gkProcessState"
            :enum-item="item.state"
          />
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-textarea
          :disabled="!isAdmin"
          class="font-italic mb-0"
          :placeholder="isAdmin ? $t('object.addNote') : $t('object.noNote')"
          prepend-inner-icon="mdi-note-outline"
          :variant="showSaveNoteBtn[index] ? 'solo' : 'plain'"
          rows="1"
          hide-details
          auto-grow
          max-rows="3"
          density="compact"
          @click="showSaveNoteBtn[index] = true"
          @blur="hideSaveButton(index)"
          v-model="item.note"
          ><template
            v-if="showSaveNoteBtn[index] && $vuetify.display.mdAndUp"
            #append-inner
          >
            <v-divider inset vertical></v-divider>
            <v-btn
              class="ma-0 ml-3"
              icon="mdi-check"
              color="white"
              size="small"
              variant="plain"
              rounded
              tile
              @click="
                handleStepUpdate(item.progressStep, item.note, item.state)
              "
            ></v-btn>
            <v-btn
              class="ma-0"
              icon="mdi-trash-can-outline"
              color="white"
              size="small"
              variant="plain"
              rounded
              tile
              @click="
                (item.note = ''),
                  (showSaveNoteBtn[index] = false),
                  handleStepUpdate(item.progressStep, item.note, item.state)
              "
            ></v-btn
          ></template>
        </v-textarea>
      </v-card-text>
      <!-- mobile -->
      <div
        class="d-flex justify-end"
        v-if="showSaveNoteBtn[index] && $vuetify.display.smAndDown"
      >
        <v-btn
          class="ma-0"
          icon="mdi-check"
          color="white"
          size="small"
          variant="plain"
          rounded
          tile
          @click="handleStepUpdate(item.progressStep, item.note, item.state)"
        ></v-btn>
        <v-btn
          class="ma-0"
          icon="mdi-trash-can-outline"
          color="white"
          size="small"
          variant="plain"
          rounded
          tile
          @click="(item.note = ''), (showSaveNoteBtn[index] = false)"
        ></v-btn>
      </div>
      <div v-if="isAdmin" class="d-flex justify-space-evenly">
        <v-btn
          variant="text"
          v-tooltip:bottom="'auf Offen setzen'"
          icon="mdi-progress-helper"
          size="small"
          :loading="loading"
          @click="handleStepUpdate(item.progressStep, item.note, 'OPEN')"
        />
        <v-btn
          variant="text"
          v-tooltip:bottom="'auf In Bearbeitung setzen'"
          icon="mdi-progress-clock"
          size="small"
          :loading="loading"
          @click="handleStepUpdate(item.progressStep, item.note, 'IN_PROGRESS')"
        />
        <v-btn
          variant="text"
          v-tooltip:bottom="'auf Abgeschlossen setzen'"
          icon="mdi-check"
          size="small"
          :loading="loading"
          @click="handleStepUpdate(item.progressStep, item.note, 'DONE')"
        />
      </div>
    </v-card>
  </v-timeline-item>
</template>

<script>
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EGkProgressStep from "@/enums/EGkProgressSteps.js";
import EGkProgressState from "@/enums/EGkProgressState.js";
import ApiService from "@/services/api.service";
import EnumDisplayLanguage from "@/components/helpers/EnumDisplayLanguage.vue";

export default {
  name: "GKObjectTimelineItem",
  components: { EnumDisplay, EnumDisplayLanguage },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      EGkProgressStep,
      EGkProgressState,
      showSaveNoteBtn: [], // Ein Array, um den Zustand jedes Speichern-Knopfs zu speichern
      notes: [],
      loading: false,
    };
  },
  methods: {
    hideSaveButton(index) {
      setTimeout(() => {
        this.showSaveNoteBtn[index] = false;
      }, 200);
    },
    async handleStepUpdate(step, note, state) {
      const stepUpdateData = {
        objectId: this.item.objectId,
        progressStep: step,
        note: note,
        state: state,
        stepIndex: this.index,
      };
      this.loading = true;
      try {
        const response = await ApiService.post(
          `gkProgress/object/${this.item.objectId}/step/${this.item.progressStep}/update`,
          stepUpdateData
        );
        if (response && response.data) {
          this.item.progressStep = step;
          this.item.note = note;
          this.item.state = state;
        }
        this.$emit("updatedStep");
      } catch (error) {
        console.error("Fehler beim Laden der Fortschrittsdaten:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    getStateIcon() {
      switch (this.item.state) {
        case "OPEN":
          return "mdi-progress-helper";
        case "IN_PROGRESS":
          return "mdi-progress-clock";
        case "DONE":
          return "mdi-check";
        default:
          return "mdi-circle-outline";
      }
    },
    getStateColor() {
      switch (this.item.state) {
        case "OPEN":
          return "grey-darken-3";
        case "IN_PROGRESS":
          return "accent";
        case "DONE":
          return "success";
        default:
          return "grey-darken-2";
      }
    },
    getIconColor() {
      switch (this.item.state) {
        case "OPEN":
          return "grey";
        case "IN_PROGRESS":
          return "grey-darken-4";
        case "DONE":
          return "grey-darken-4";
        default:
          return "grey";
      }
    },
  },
  mounted() {
    console.log(this.item);
  },
};
</script>
