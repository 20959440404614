// Requires jQuery!
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
$.ajax({
    url: "https://octilia.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/g2slup/b/9/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=de-DE&collectorId=e7805144",
    type: "get",
    cache: true,
    dataType: "script"
});

