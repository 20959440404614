<script>
import ApiService from "@/services/api.service";
import UserFullInfo from "@/components/user/UserFullInfo.vue"; // Assuming this is the path to the UserFullInfo component
import { debounce } from 'lodash';
import PaginationWrapper from "@/components/PaginationWrapper.vue";
export default {
  name: "AdminFullUserList",
  components: {
    PaginationWrapper,
    UserFullInfo
  },
  data() {
    return {
      filter: {
        search: ""
      }
    }
  },
  watch: {
  }
};
</script>

<template>
  <v-container>
  <pagination-wrapper :filter="filter" url="user/info/list/page/">
    <template #filter="{ filter }">
        <v-text-field
            density="compact"
            append-inner-icon="mdi-magnify"
            hide-details
            v-model="filter.search"
            :label="$t('form.search') + '...'"
        />
    </template>
    <template #item="{item}">
      <UserFullInfo :user="item"/>
    </template>
  </pagination-wrapper>
  </v-container>
</template>

<style scoped>
/* Add any necessary styles here */
</style>