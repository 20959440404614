<template>
  <div>
  <v-btn-toggle :disabled="readonly" v-model="objectReactive.sellType" mandatory color="accent" class="my-2" selected-class="ma-1">
    <v-btn value="VERKAUF">{{$t('object.sale')}}</v-btn>
    <v-btn value="VERRENTUNG">{{$t('object.retirement')}}</v-btn>
  </v-btn-toggle>
  <TypeOfObjectSelection :disabled-p="readonly" @update="updateObjectType" :type-of-object="objectReactive.objectType" class="my-2"/>
    <SquareMeterSlider :disabled-p="readonly" @update="updateLivingArea" :label="$t('object.flatArea')" :max-value="350" :value="objectReactive.livingArea"/>
    <SquareMeterSlider :disabled-p="readonly" v-if="objectReactive.objectType!='TYPE_WOHNUNG'" @update="updateLandArea" :label="$t('object.baseArea')" :max-value="1500" :value="objectReactive.landArea"/>
    <SquareMeterSlider :disabled-p="readonly" v-if="authStore.hasRole('ROLE_ADMIN')" @update="updateUsableArea" :label="$t('object.usableArea')" :max-value="1500" :value="objectReactive.usableArea"/>

  <EditObjectOwner :disabled-p="readonly" @updateOwner="updateOwner" :owner="objectReactive.objectOwner"/>
  <v-text-field :disabled="readonly" type="number" v-model="objectReactive.returnOfInvestment" :label="$t('object.istFactor')" class="my-2"/>
  <SelectGKUser v-if="authStore.hasRole('ROLE_ADMIN')" v-model:user-id="objectReactive.geschaeftskundenId" :disabled="readonly" />
    <AddressSmallVariant :disabled-p="readonly" @update="updateAddress" :address="objectReactive.address"/>
    <router-view/>
  <AddImages :disabled-p="readonly" :images="objectReactive.images" @updateImages="updateImages"/>
  </div>
</template>

<script>
import EstateObject from "@/models/estateObject";
import {useAuthUserStore} from "@/stores/modules/auth.module";
import TypeOfObjectSelection from "@/components/objects/create/TypeOfObjectSelection";
import SquareMeterSlider from "@/components/objects/create/SquareMeterSlider";
import AddImages from "@/components/objects/create/AddImages";
import AddressSmallVariant from "@/components/address/AddressSmallVariant";
import EditObjectOwner from "@/components/objects/create/EditObjectOwner";
import SelectGKUser from "@/components/geschaeftskunde/SelectGKUser.vue";

export default {
  name: "ObjectData",
  components: {SelectGKUser, EditObjectOwner, AddressSmallVariant, AddImages,  SquareMeterSlider, TypeOfObjectSelection},
  setup(){
    const authStore = useAuthUserStore();
    return{
      authStore
    }
  },
  computed:{
    currentUser(){
      return this.authStore.user;
    },
    objectReactive:{
      get(){
        return this.object;
      },set(value){
        this.$emit("updateObject",value);
      }
    }
  },
  props:{
    readonly:Boolean,
    object:{
      default:new EstateObject(),
      type:Object
    }
  },
  methods:{
    updateAddress(value){
      this.objectReactive.address=value;
    },
    updateOwner(value){
      this.objectReactive.objectOwner=value;
    },
    updateObjectType(value){

      this.objectReactive.objectType=value;
    },
    updateLandArea(value){
      this.objectReactive.landArea=value;
    },
    updateLivingArea(value){
      this.objectReactive.livingArea=value;
    },
    updateUsableArea(value){
      this.objectReactive.usableArea=value;
    },
    updateImages(value){
      this.objectReactive.images=value;
    }

  }
}
</script>

<style scoped>

</style>