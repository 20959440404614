<template>
  <v-dialog persistent v-model="showReactive">
<template #activator="{props}">
    <StepListItem :disabled="disabled" v-bind="props" :title="$t('process.ownerConfirmation.title')" :status="status" :message="message.text" :message-color="message.color"/>
</template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>{{$t('process.ownerConfirmation.title')}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
       <div v-html="$t('process.ownerConfirmation.text')"></div>
<br/>
<br/>
        <v-alert color="warning" icon="$warning" density="compact" :text="$t('process.ownerConfirmation.alert')">
          </v-alert>
          <br/>
        <small>{{$t('process.ownerConfirmation.footNote')}}</small>
      <br/>
      <br/>
        <v-checkbox color="green" v-model="consentFormReactive.consent">
        <template #label>
          <small v-text="$t('process.ownerConfirmation.confirm')">

          </small>
        </template>
        </v-checkbox>
        <v-file-input v-if="!consentFormReactive?.contract?.file" @change="setImage" :label="$t('process.ownerConfirmation.title')">
          <template #details>
            <a class="text-accent" @click.prevent="download('einverstaendniserklaerung_download')">{{$t('process.ownerConfirmation.download')}}</a>
          </template>
        </v-file-input>
        <div v-else>
          <div  v-if="!reUpload" class="d-flex flex-wrap justify-center">
          <v-btn block color="accent" class="text-white" @click.prevent="downloadFile">{{$t('files.download')}}</v-btn>
            <v-btn class="ma-2" variant="text" @click="reUpload=!reUpload"><v-icon>mdi-reload</v-icon> {{$t('files.upload')}}</v-btn>
          </div>
            <v-file-input v-else  @change="setImage" :label="$t('process.ownerConfirmation.upload')">
            <template #details>
              <a class="text-accent" @click.prevent="download('einverstaendniserklaerung_download')">{{$t('process.ownerConfirmation.download')}}</a>
            </template>
          </v-file-input>

        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="showReactive=false">{{$t('buttons.cancel')}}</v-btn><v-spacer/><v-btn @click="save(consentFormReactive)">{{$t('buttons.save')}}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import StepListItem from "@/components/objects/progress/StepListItem";
import consentForm from "@/models/consentForm";
import File from "@/models/file";
import {convertBase64} from "@/services/image.service";
import {downloadFileFromBase64, getDownloadable} from "@/services/file.service";
export default {
  name: "OwnerConfirmationStep",
  components: {StepListItem},
  data(){
    return{
      file:null,
      reUpload:false,
      checkbox:false
    }
  },
  props:{
    disabled:Boolean,
    show:Boolean,
    consentForm:{
      default:new consentForm(),
      value:consentForm
    }
  },
  methods:{
    downloadFile(){
      downloadFileFromBase64(this.consentFormReactive.contract);
    },
    async download(id) {
      console.log("id",id);
     const file = await getDownloadable(id);
     console.log("File",file);
     downloadFileFromBase64(file);
    },
    async setImage(e) {
      console.log(e);
      let files = e.target.files || e.dataTransfer.files;

      if(!files.length) return;
      else{
        let file= files[0];
        this.consentFormReactive.contract = new File(file.name, file.type,file.size, await convertBase64(file))

      }
    },
    save(consent){
      this.$emit("update",consent);
      this.$emit("toggleDialog",false);
    }
  },
  computed:{
    showReactive:{
      get(){
        return this.show;
      },set(value){
        this.$emit('toggleDialog',value)
      }
    },
    consentFormReactive:{
      get(){
        return this.consentForm;
      },set(value){
        this.$emit("update",value);
      }
    },
    message(){
      if(!this.consentFormReactive.consent) return {
        color:'text-error',
        text:this.$t('process.ownerConfirmation.noConfirmation2')
      };
      else if(!this.consentFormReactive.contract.file) return {
        color:'text-error',
        text:this.$t('process.ownerConfirmation.noConfirmation')
      };
      else return {
          color:null,
          text:null
        };
    },
    status(){
      if(this.consentFormReactive.consent && this.consentFormReactive.contract.file) return 'success';
      else return 'error'
    }
  }
}
</script>

<style scoped>

</style>