<template>
  <v-list-subheader>{{
    $t("settingsPopup.admin.objects.verification.createdBy")
  }}</v-list-subheader>
  <v-list-item
    align="left"
    v-if="user"
    :title="user.firstName + ' ' + user.lastName"
  >
    <template #subtitle>
      <span v-if="progress" v-html="'Level ' + progress.level"></span>
    </template>
    <template #append>
      <v-btn icon :href="'tel:' + user.telephone" variant="text"
        ><v-icon icon="mdi-phone"></v-icon
      ></v-btn>
      <v-btn icon :href="'mailto:' + user.email" variant="text"
        ><v-icon icon="mdi-mail"></v-icon
      ></v-btn>
    </template>
  </v-list-item>
</template>

<script>
import UserService from "@/services/user.service";
import { getProgress } from "@/services/progress.service";
export default {
  name: "UserDisplaySmall",
  data() {
    return {
      user: null,
      progress: null,
    };
  },
  created() {
    this.getUser();
    this.getProgress();
  },
  methods: {
    async getUser() {
      this.user = await UserService.getUser(this.userId);
    },
    async getProgress() {
      this.progress = await getProgress(this.userId);
    },
  },
  props: {
    userId: Number,
  },
  computed: {},
};
</script>

<style scoped></style>
