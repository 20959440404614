<template>
  <v-autocomplete
    v-model="selectedUsers"
    :items="geschaeftskunden"
    item-title="fullName"
    item-value="id"
    :label="$t('filter.geschaeftskunden')"
    chips
    variant="solo"
    bg-color="grey-darken-3"
    flat
    hide-details
    rounded="lg"
    closable-chips
    density="compact"
    @update:modelValue="updateFilter"
  >
    <template #chip="{ props, item }">
      <v-chip v-bind="props" :text="item.raw.fullName" />
    </template>
    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="item.raw.fullName"
        :subtitle="item.raw.email"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { getUsersByRole } from "@/services/user.service";

export default {
  name: "GeschaeftskundenFilter",
  props: {
    modelValue: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      geschaeftskunden: [],
      selectedUsers: [],
    };
  },
  async created() {
    try {
      this.geschaeftskunden = await getUsersByRole("ROLE_GESCHAEFTSKUNDE");
    } catch (error) {
      console.error("Fehler beim Laden der Geschäftskunden:", error);
    }
  },
  methods: {
    updateFilter() {
      this.$emit("update:modelValue", this.selectedUsers);
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.selectedUsers = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
