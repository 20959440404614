<template>
  <span>{{ getEnumTitle }}</span>
</template>

<script>
export default {
  name: "EnumDisplay",
  props: {
    enumObject: {
      type: Object,
      required: true,
    },
    enumKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    getEnumTitle() {
      const enumValue = this.enumObject[this.enumKey];
      if (!enumValue) return this.enumKey;
      return enumValue[this.$i18n.locale === "de" ? "german" : "english"];
    },
  },
};
</script>
