<script>
import GreetingUser from "@/components/dashboard/GreetingUser.vue";
import ObjectCaroussel from "@/components/objects/ObjectCaroussel.vue";
import GKAllObjects from "@/views/geschaeftskunde/GKAllObjects.vue";

export default {
  name: "gkDashboardView",
  components: {GKAllObjects, ObjectCaroussel, GreetingUser}
}
</script>

<template>
<div>
  <GreetingUser ref="dashboard-greeting"/>
  <v-container>
    <h3 class="text-left mb-5 mt-10 heading-desktop">{{$t('yourObjects')}}</h3>
    <GKAllObjects/>
  </v-container>


</div>
</template>

<style scoped>

</style>