import ApiService from '@/services/api.service';

export async function createEstateObject(consentForm,estateObject) {
    const response = await ApiService.post("object/create", {
        estateObject:estateObject,
        consentForm:consentForm
    });
    return response.data;
}

export async function isGkObject(id){
    const response = await ApiService.get("object/"+id+"/isGk");
    return response.data;
}

export async function updateEstateObject(estateObject) {
    const response = await ApiService.post("object/update", estateObject);
    return response.data;
}
export async function countMySaledEstateObjects() {
    const response = await ApiService.get("object/mine/count/saled");
    return response.data;
}
export async function getEstateObject(objectId){
    const response = await ApiService.get("object/get/"+objectId);
    return response.data;
}
export async function getConsentForm(objectId){
    const response = await ApiService.get("object/consent/get/"+objectId);
    return response.data;
}
export async function saveConsentForm(consentObject){
    console.log("Consent",consentObject);
    const response = await ApiService.post("object/consent/save",consentObject);
    return response.data;
}

export async function getContract(objectId){
    const response = await ApiService.get("object/contract/get/"+objectId);
    return response.data;
}
export async function getSale(objectId){
    const response = await ApiService.get("object/sale/get/"+objectId);
    return response.data;
}
export function getObjectName(object){
    let type = object.objectType.replace('TYPE_','');
    let address= object.address.postcode+' '+object.address.city+' '+object.address.street;
    return type+" "+address;
}

export async function getAllUsersObjects() {
    const response = await ApiService.get("object/mine/all");
    return response.data;
}