<template>
  <h3>{{ $t("settingsPopup.admin.title") }}</h3>
  <v-list style="background-color: transparent" align="left">
    <v-list-item
      class="my-2"
      to="admin/objekte"
      :title="$t('settingsPopup.admin.objects.title')"
      append-icon="mdi-chevron-right"
      :subtitle="$t('settingsPopup.admin.objects.subtitle')"
    >
    </v-list-item>
    <v-list-item
      class="my-2"
      to="admin/weiterbildung/section"
      append-icon="mdi-chevron-right"
      :title="$t('settingsPopup.admin.trainingCenter.title')"
      :subtitle="$t('settingsPopup.admin.trainingCenter.subtitle')"
    >
    </v-list-item>
    <v-list-item
      class="my-2"
      to="admin/payout"
      append-icon="mdi-chevron-right"
      :title="$t('settingsPopup.admin.payout.title')"
      :subtitle="$t('settingsPopup.admin.payout.subtitle')"
    ></v-list-item>
    <v-list-item
      class="my-2"
      to="admin/statistic"
      append-icon="mdi-chevron-right"
      :title="$t('settingsPopup.admin.statistics.title')"
      :subtitle="$t('settingsPopup.admin.statistics.subtitle')"
    ></v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AdminWrapper",
};
</script>

<style scoped></style>
