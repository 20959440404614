<template>
  <div v-if="contract" class="my-10">
    <!--<v-file-input class="my-5" @change="setImage" :label="fileLabel" placeholder="Maklervertrag hochladen...">
      <template #details>
        <v-btn @click="download" v-if="contract.contract" size="small" variant="plain">Vertrag herunterladen</v-btn>
      </template>
    </v-file-input>-->
    <DatePicker
      :label="$t('settingsPopup.admin.objects.edit.agentContract.dateEnd')"
      :date="contract.cancellationPeriodStart"
      @update="updateDate"
    />
    <v-checkbox
      v-model="contract.signed"
      :label="
        $t('settingsPopup.admin.objects.edit.agentContract.signedCheckbox')
      "
    ></v-checkbox>
    <v-btn block @click="saveContract">{{
      $t("settingsPopup.admin.objects.edit.agentContract.saveBtn")
    }}</v-btn>
  </div>
  <div v-else class="my-10">
    <v-btn
      :loading="loading"
      @click="createMaklerVertrag"
      block
      color="secondary"
      >{{
        $t("settingsPopup.admin.objects.edit.agentContract.loadingBtn")
      }}</v-btn
    >
  </div>
</template>

<script>
import {
  createContract,
  editContract,
  getContract,
} from "@/services/admin.service";
import { convertBase64 } from "@/services/image.service";
import File from "@/models/file";
import { downloadFileFromBase64 } from "@/services/file.service";
import DatePicker from "@/components/admin/object/DatePicker";

export default {
  name: "EditMaklerVertrag",
  components: { DatePicker },
  props: {
    objectId: Number,
  },
  data() {
    return {
      show: false,
      contract: null,
      loading: false,
    };
  },
  computed: {
    fileLabel() {
      if (!this.contract) return null;
      if (
        this.contract != null &&
        this.contract.contract &&
        this.contract.contract.filename != null
      )
        return this.contract.contract.filename;
      else return "Maklervertrag";
    },
  },
  created() {
    this.getMaklerVertrag();
  },
  methods: {
    updateDate(value) {
      this.contract.cancellationPeriodStart = value;
    },
    download() {
      downloadFileFromBase64(this.contract.contract);
    },
    async setImage(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;
      else {
        let file = files[0];
        if (this.contract.contract != null) {
          this.contract.contract.file = await convertBase64(file);
          this.contract.contract.filename = file.name;
          this.contract.contract.contentType = file.type;
          this.contract.contract.size = file.size;
        } else {
          this.contract.contract = new File(
            file.name,
            file.type,
            file.size,
            await convertBase64(file)
          );
        }
      }
    },
    async saveContract() {
      this.loading = true;
      this.contract = await editContract(this.contract);
      this.loading = false;
      location.href = "/admin/objekt/" + this.objectId;
    },
    async createMaklerVertrag() {
      this.contract = await createContract(this.objectId);
    },
    async getMaklerVertrag() {
      this.contract = await getContract(this.objectId);
    },
  },
};
</script>
